import { Component, Input, ViewChild } from '@angular/core';
import { AlertController, IonModal, ModalController } from '@ionic/angular';
import { IKioskCashSubmission } from 'src/app/core/models/kiosks/kiosk-cash-submission.model';
import { ICashTicketRequest } from 'src/app/core/models/ticket-purchases/cash-ticket-request.model';
import { KioskPurchasesService } from 'src/app/core/services/api/modern/kiosk-purchases/kiosk-purchases.service';
import { TicketPurchasesService } from 'src/app/core/services/api/modern/ticket-purchases/ticket-purchases.service';

@Component({
  selector: 'app-checkout-cash',
  templateUrl: './cash-modal.component.html',
  styleUrls: ['./cash-modal.component.scss'],
})
export class CheckoutCashComponent {
  @ViewChild(IonModal) modal!: IonModal;
  @Input() cashSubmission!: IKioskCashSubmission | undefined;
  @Input() cashTicketRequests!: ICashTicketRequest[] | undefined;
  @Input() accessToken!: string;
  @Input() total!: number;
  @Input() ticketConfigurationID: string | undefined;

  cashGiven: number | undefined;

  constructor(
    private modalCtrl: ModalController,
    private kioskPurchasesService: KioskPurchasesService,
    private ticketPurchasesService: TicketPurchasesService,
    private alertController: AlertController
  ) {}

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async submitCash() {
    let response: any;

    if (!this.cashGiven) {
      response = await this.submitCashRequest();

      const alert = await this.alertController.create({
        header: 'Cash Transaction Complete',
        message: 'The transaction has been completed successfully.',
        buttons: ['OK'],
      });

      await alert.present();

      await this.dismissModal(response);

      return;
    }

    const cashBack = this.cashGiven - this.total;

    if (cashBack < 0) {
      const alert = await this.alertController.create({
        header: 'Not Enough Cash Received',
        message: 'Please enter a valid amount of cash.',
        buttons: ['OK'],
      });

      await alert.present();

      return;
    }

    response = await this.submitCashRequest();

    const alert = await this.alertController.create({
      header: 'Cash Back',
      message: 'Please give the customer $' + cashBack.toFixed(2) + ' back in cash.',
      buttons: [
        {
          text: 'OK',
          handler: async () => {
            const alert = await this.alertController.create({
              header: 'Cash Transaction Complete',
              message: 'The transaction has been completed successfully.',
              buttons: ['OK'],
            });

            await alert.present();
          },
        },
      ],
    });

    await alert.present();

    await this.dismissModal(response);
  }

  async submitCashRequest() {
    let response: any = null;

    if (this.cashTicketRequests) {
      response = await this.ticketPurchasesService.submitCash(
        this.cashTicketRequests,
        this.ticketConfigurationID!
      );
    } else {
      response = await this.kioskPurchasesService.submitCash(this.cashSubmission!);
    }

    if (response.stack && response.stack.includes('Error')) {
      await this.modalCtrl.dismiss({ success: false }, 'error');
      return;
    }

    return response;
  }

  async dismissModal(response: any) {
    if (this.cashTicketRequests) {
      await this.modalCtrl.dismiss({ success: true }, 'success');
      return;
    }

    await this.modalCtrl.dismiss({ success: true, kioskOrderID: response.data.KioskOrderID }, 'success');
  }
}
