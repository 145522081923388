import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import {PaymentService} from "../../../../core/services/api/modern/payment/payment.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../core/services/api/api.service";
import {IUserProfile} from "../../../../core/models/users/user-profile";
import {UtilsService} from "../../../../core/services/utils/utils.service";
import {ErrorService} from "../../../../core/services/error/error.service";
import {environment} from "../../../../../environments/environment";


@Component({
  selector: 'app-add-bank-account',
  templateUrl: './add-bank-account.component.html',
  styleUrls: ['./add-bank-account.component.scss'],
})
export class AddBankAccountComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  formGroup: FormGroup;
  @Input() name: string;
  @Input() type: string;
  @Input() routingNumber: string;
  @Input() accountNumber: string;
  user: IUserProfile = {};
  customer: any;
  token: string = '';

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    public modalCtrl: ModalController,
    private errorService : ErrorService,
    private paymentService : PaymentService,
    private fb: FormBuilder,
    private utilsService : UtilsService,
  ) {
    this.name = '';
    this.type = '';
    this.routingNumber = '';
    this.accountNumber = '';

    this.formGroup = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(49)]],
      type : ['', [Validators.required]],
      routingNumber : ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9)]],
      accountNumber : ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
    const {data: userData} = await this.apiService.getV1('user', 'find', {id: this.context.UserID});
    this.user = userData['Data'];
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  async saveAccount() {

    const {data: token} = await this.paymentService.createClientToken(this.customer.ID);
    let clientToken = token['Data'];

    let customerData = {
      "name" : this.formGroup.get('name')?.value,
      "type" : this.formGroup.get('type')?.value.toLowerCase().replace(" ", ""),
      "routingNumber" : this.formGroup.get('routingNumber')?.value,
      "accountNumber" : this.formGroup.get('accountNumber')?.value,
    }
    let body:BodyInit = JSON.stringify(customerData);
    let url : string = environment.dwolla.url + "/customers/{id}/funding-sources";

    url = url.replace('{id}', this.customer.ID)
    const response = await this.paymentService.addFundingSource(url, body, clientToken);

    if(response.ok) {
      this.modalCtrl.dismiss(true);
    } else {
      this.errorService.showError(response.status.toString(), 15, response.statusText);
    }
  }

}
