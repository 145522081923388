<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Manage Calendars</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-title *ngIf="contextOrganization && contextOrganization.OrganizationDisplayTitle" style="padding-top: 10px">{{contextOrganization.OrganizationDisplayTitle}} Calendars</ion-title>
  <ion-title *ngIf="contextOrganization && !contextOrganization.OrganizationDisplayTitle">{{contextOrganization.OrganizationTitle}} Calendars</ion-title>
<ion-item class="ion-padding-horizontal top-text background-transparent">
  Scroll down and select calendars to add them to your calendars. When you are finished, hit the "Save" button.
</ion-item>
  <div>
    <ion-list [inset]="true"
              class="school-list ion-padding" slot="content" style="margin-bottom: 0;">
      <div *ngFor="let selectedCalendar of selectedCalendarsForUser" class="school-info" >
        <ion-icon class="first-element" #refEl name="checkmark-circle-outline" size="large"
                  (click)="removeCalendar(this.selectedCalendar, refEl)"/>
        <span class="second-element">{{this.selectedCalendar.Title}}</span>
      </div>
    </ion-list>
  </div>
  <div>
    <ion-list [inset]="true"
              class="school-list ion-padding" slot="content">
      <div *ngFor="let calendar of calendarsForSchool" class="school-info" >
        <ion-icon class="first-element" #refEl name="ellipse-outline" size="large"
                  (click)="addCalendar(this.calendar, refEl)"/>&nbsp;
        <span class="second-element">{{this.calendar.Title}}</span>
      </div>
    </ion-list>
  </div>
  <div class="footer-background">
    <ion-button class="ion-padding save-button"
      type="submit"
      appPreventMultiClickEvents
      (preventMultiClicks)="saveCalendars()"
      expand="block">
      <ion-text> Save </ion-text>
    </ion-button>
  </div>
</ion-content>
