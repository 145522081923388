<ion-header>
  <ion-toolbar class="toolbar" (click)="expanded = !expanded">
    <div id="container">
      <div id="header-container">
        <ion-text class="type-text"
          ><ion-icon name="calendar-outline" class="dropdown-arrow"></ion-icon> All My Calendars</ion-text
        >
      </div>
      <ion-icon name="chevron-up" class="dropdown-arrow" *ngIf="expanded"></ion-icon>
      <ion-icon name="chevron-down" class="dropdown-arrow" *ngIf="!expanded"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>
<ion-content *ngIf="expanded" class="dropdown" [style.height]="getDropdownHeight()">
  <div id="list-container">
    <ion-item lines="none" *ngFor="let item of calendars" [style.background-color]="item.BackColor" [style.color]="item.TextColor">
      <ion-checkbox (ionChange)="updateParent($event, item)" [(ngModel)]="item.Visible" labelPlacement="end">{{
        item.Title
      }} <span *ngIf="item.OrganizationTitle !== item.Title && this.context.ApplicationType !== 'Officials'">({{item.OrganizationTitle}})</span></ion-checkbox>
    </ion-item>
    <ion-button *ngIf="showManageModal"
      class="manage-button"
      type="submit"
      appPreventMultiClickEvents
      (preventMultiClicks)="openManageCalendarsModal()"
      expand="block">
      <ion-text>Manage My Calendars</ion-text>
    </ion-button>
  </div>
</ion-content>
