import { Component, OnInit } from '@angular/core';
import {IonIcon, ModalController} from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import {CalendarService} from "../../../../core/services/api/legacy/calendar/calendar.service";
import {Calendar} from "../../../../core/models/calendar/calendar";
import IContextOrganizationModel from "../../../../core/models/context/context-organization.models";
import {EventService} from "../../../../core/services/api/legacy/event/event.service";

@Component({
  selector: 'app-manage-calendars',
  templateUrl: './manage-calendars.component.html',
  styleUrls: ['./manage-calendars.component.scss'],
})

export class ManageCalendarsComponent implements OnInit {
  context!: ContextModel;
  calendarsForSchool: Calendar[] = [];
  selectedCalendarsForUser: Calendar[] = [];
  currentView: string = 'Available Calendars';
  itemClass: string = 'background-color: lightgrey;';
  contextOrganization!: IContextOrganizationModel;
  organizations: IContextOrganizationModel[] =[];


  constructor(
    private authService: AuthService,
    public modalCtrl: ModalController,
    private calendarService: CalendarService,
    private eventService: EventService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
    this.contextOrganization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);
    this.organizations = (await this.authService.getContextOrganizations()).sort((a, b) =>
      a.OrganizationTitle!.localeCompare(b.OrganizationTitle!)
    );
    const response: any = await this.eventService.getCalendars(
      this.contextOrganization?.OrganizationId as string,
      this.context.AccessToken as string
    );
    for(let calendar of response.data["Data"]){
      if(calendar.Type !== 'District' && calendar.Type !== 'Organization'){
        this.selectedCalendarsForUser.push(calendar);
        for (let i = 0; i < this.calendarsForSchool.length; i++) {
          if (this.calendarsForSchool[i].ID === calendar.ID) {
            this.calendarsForSchool.splice(i, 1);
           i = this.calendarsForSchool.length;
         }
        }
      }
    }
    const { data } = await this.calendarService.getCalendarsForSchool(this.contextOrganization.OrganizationId!);
    if(data.Data){
      this.calendarsForSchool = data["Data"];
    }
    this.sortCalendars();
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  saveCalendars() {
    let calendarIds :string[] = [];
    for(let calendar of this.selectedCalendarsForUser){
      calendarIds.push(calendar.ID);
    }
    this.calendarService.syncCalendars(this.contextOrganization.OrganizationId!, calendarIds, this.context.AccessToken!);
    this.modalCtrl.dismiss(true);
  }

  setAvailableCalendars() {
    this.currentView = "Available Calendars"
    this.calendarsForSchool.sort((a, b) =>
      a.Title!.localeCompare(b.Title!)
    );
  }

  setMyCalendars(){
    this.currentView = "My Calendars"
    this.selectedCalendarsForUser.sort((a, b) =>
      a.Title!.localeCompare(b.Title!)
    );
  }

  addCalendar(calendar: Calendar, element: IonIcon) {
      this.selectedCalendarsForUser.push(calendar);
      for (let i = 0; i < this.calendarsForSchool.length; i++) {
        if (this.calendarsForSchool[i].ID === calendar.ID) {
          this.calendarsForSchool.splice(i, 1);
        }
      }
    this.sortCalendars();
  }

  removeCalendar(calendar: Calendar, element: IonIcon) {
    this.calendarsForSchool.push(calendar);
    for (let i = 0; i < this.selectedCalendarsForUser.length; i++) {
      if (this.selectedCalendarsForUser[i].ID === calendar.ID) {
        this.selectedCalendarsForUser.splice(i, 1);
      }
    }
    this.sortCalendars();
  }

  sortCalendars(){
    this.selectedCalendarsForUser = this.selectedCalendarsForUser.sort((a, b) =>
      a.Title!.localeCompare(b.Title!)
    );
    this.calendarsForSchool = this.calendarsForSchool.sort((a, b) =>
      a.Title!.localeCompare(b.Title!)
    );
  }
}
