<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="medium" (click)="cancel()" onkeydown="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>{{ modalTitle }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <div *ngIf="modalTitle === 'Send Receipt'">
    <ion-row>
      <ion-label>Phone Number:</ion-label>
      <ion-input type="tel" [(ngModel)]="sendReceiptPhoneNumber" placeholder="(123) 123-1234"> </ion-input>
    </ion-row>
    <ion-row>
      <ion-button (click)="skipPhoneNumber()" [strong]="true" class="skipPhoneButton" onkeydown="skipPhoneNumber()"
        >Do Not Send</ion-button
      >
      <ion-button
        (click)="applyPhoneNumber()"
        [strong]="true"
        class="sendNumberButton"
        onkeydown="applyPhoneNumber()"
        >Send</ion-button
      >
    </ion-row>
  </div>

  <div *ngIf="modalTitle === 'Checkout'">
    <div *ngIf="!!kioskCheckoutResponse">
      <ion-row>
        <ion-text class="checkout-title">Subtotal:</ion-text>
        <ion-text> {{ kioskCheckoutResponse.SubTotalDollars | currency }}</ion-text>
      </ion-row>

      <ion-row>
        <ion-text class="checkout-title">Fee:</ion-text>
        <ion-text>{{ kioskCheckoutResponse.FeeDollars | currency }}</ion-text>
      </ion-row>

      <ion-row>
        <ion-text class="checkout-title">Total:</ion-text>
        <ion-text> {{ kioskCheckoutResponse.TotalDueDollars | currency }}</ion-text>
      </ion-row>
    </div>

    <div *ngIf="!!!kioskCheckoutResponse">
      <ion-row>
        <ion-skeleton-text [animated]="true" class="checkout-title-skeleton"></ion-skeleton-text>
      </ion-row>

      <ion-row>
        <ion-skeleton-text [animated]="true" class="checkout-title-skeleton"></ion-skeleton-text>
      </ion-row>

      <ion-row>
        <ion-skeleton-text [animated]="true" class="checkout-title-skeleton"></ion-skeleton-text>
      </ion-row>
    </div>

    <ion-row>
      <ion-button
        (click)="swipeCreditCard()"
        onkeydown="swipeCreditCard()"
        [strong]="true"
        expand="block"
        class="swipeCardButton"
        >Swipe/Tap Card</ion-button
      >
    </ion-row>
  </div>
</ion-content>
