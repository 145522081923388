import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private apiService: ApiService) {}

  async getCalendarsForSchool(orgId: string) {
    return await this.apiService.getV1(
      'Calendar',
      'GetPublicByOrganizationID',
      {
        id: orgId,
      },
    );
  }

  async syncCalendars(organizationId : string, calendarIds : string[], accessToken : string){
    return await this.apiService.postV1(
      {OrganizationId: organizationId,
        Calendars: calendarIds},
      {
        m: 'Calendar',
        a: 'SyncSubscriptions',
        },
      undefined,
        accessToken);
  }
}
