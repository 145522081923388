<ion-tabs>
  <ion-tab-bar slot="home">
    <ion-tab-button tab="home">
      <div class="icon-container">
        <ion-img src="../../../../assets/images/appIcon/appicon.png" class="home-icon"></ion-img>
        <ion-text>Home</ion-text>
      </div>
    </ion-tab-button>
    <ion-tab-button tab="calendar">
      <div class="icon-container">
        <fa-icon icon="calendar-days" size="2x" class="icon"></fa-icon>
        Calendar
      </div>
    </ion-tab-button>
    <ion-tab-button tab="tickets">
      <div class="icon-container">
        <fa-icon icon="ticket" size="2x" class="icon"></fa-icon>
        Tickets
      </div>
    </ion-tab-button>
    <ion-tab-button tab="messages">
      <div class="icon-container">
        <fa-icon icon="envelope" size="2x" class="icon"></fa-icon>
        Messages
      </div>
    </ion-tab-button>
    <ion-tab-button tab="kiosk" *ngIf="userHasKioskAccess | async" (click)="kiosk()" onkeydown="kiosk()">
      <div class="icon-container">
        <fa-icon icon="store" size="2x" class="icon"></fa-icon>
        Kiosk
      </div>
    </ion-tab-button>
    <ion-tab-button id="popover" selected="false">
      <div class="icon-container" id="popover-button">
        <fa-icon icon="bars" size="2x" class="icon"></fa-icon>
        More
      </div>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>

<ion-popover trigger="popover-button" [dismissOnSelect]="false">
  <ng-template>
    <ion-content>
      <ion-list>
        <ion-item
          [button]="true"
          [detail]="false"
          appPreventMultiClickEvents
          (preventMultiClicks)="refresh()"
          *ngIf="showRefresh"
          >Refresh</ion-item
        >
        <ion-item [button]="true" [detail]="false" appPreventMultiClickEvents (preventMultiClicks)="uploadLogs()">Upload Logs</ion-item>
        <ion-item [button]="true" [detail]="false" appPreventMultiClickEvents (preventMultiClicks)="pay()">Pay</ion-item>
        <ion-item [button]="true" [detail]="false" (click)="dismissPopover()">Settings</ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>
