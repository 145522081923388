import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(private apiService: ApiService) {}

  async findEvent(eventId: string){
    return await this.apiService.getV1(
      'Event',
      'Find',
      {
        id: eventId
      },
    )
  }

  async getCalendars(organizationId: string, accessToken: string) {
    return await this.apiService.getV1(
      'User',
      'GetCalendarSubscriptionsByOrganizationID',
      {
        id: organizationId,
      },
      accessToken
    );
  }

  async getCalendarData(
    startDate: Date,
    endDate: Date,
    ids: Array<string>,
    accessToken: string,
    TimeZone: string
  ) {
    return await this.apiService.postV1(
      {
        TimeZone: TimeZone,
        StartDate: format(startDate, 'yyyy-MM-dd'),
        EndDate: format(endDate, 'yyyy-MM-dd'),
        CalendarIDs: ids,
      },
      {
        m:  'Event',
        a: 'GetByCalendarIDs',
        src:'sharedCalendarEvents',
      },
      undefined,
      accessToken
    );
  }

  async getOfficialsData(
    startDate: Date,
    endDate: Date,
    ids: Array<string>,
    accessToken: string,
    timezone: string,
    officialId: string
  ) {
    const endpointMap: any = {
      accepted: 'GetAllWithAcceptedSlotsByCurrentOfficial',
      open: 'GetAllWithOpenAssignAvailable',
      select: 'GetAllWithSelectiveAssignAvailableByCurrentOfficial',
      declined: 'GetAllDeclinedByCurrentOfficial',
      blocks: 'GetAllBlackoutRulesByOfficial',
    };
    const promises = ids.map((id) =>
      this.apiService.getV1(
        id === 'blocks' ? 'Official' : 'EventAthletics',
        endpointMap[id],
        {
          id: id === 'blocks' ? officialId : undefined,
          tz: timezone || 'America/New_York',
          start: format(startDate, 'yyyy-MM-dd'),
          end: format(endDate, 'yyyy-MM-dd'),
        },
        accessToken
      )
    );

    let events: any = [];
    const values = await Promise.all(promises);
    values.forEach((value) => {
      if (value.data['Data']) {
        const data = value.data['Data'];
        events.push(...data);
      }
    });

    return events;
  }

  async updateCalendarVisibility(ids: Array<string>, accessToken: string, flag: boolean) {
    let flagString = flag ? 'true' : 'false';
    await this.apiService.postV1(
      {
        Visible: flagString,
        CalendarIDs: ids,
      },
      {m:'Calendar',
       a: 'UpdateVisible'},
      undefined,
      accessToken
    );
  }
}
