import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';
import { IKioskSessionStart } from 'src/app/core/models/kiosks/kiosk-session-start.model';
import { IKioskSessionEnd } from 'src/app/core/models/kiosks/kiosk-session-end.model';

@Injectable({
  providedIn: 'root',
})
export class KioskSessionsService {
  private readonly controller = 'KioskSessions';

  constructor(private apiService: ApiService) {}

  start(model: IKioskSessionStart) {
    return this.apiService.post(this.controller, 'Start', model);
  }

  end(model: IKioskSessionEnd) {
    return this.apiService.post(this.controller, 'End', model);
  }

  getByID(kioskSessionID: string) {
    return this.apiService.get(this.controller, 'GetByID', { kioskSessionID });
  }

  refund(paymentIntentID: string, kioskSessionID: string, organizationID: string) {
    return this.apiService.post(this.controller, 'Refund', undefined, {
      paymentIntentID,
      kioskSessionID,
      organizationID,
    });
  }

  refundCash(kioskOrderID: string, kioskSessionID: string, organizationID: string) {
    return this.apiService.post(this.controller, 'RefundCash', undefined, {
      kioskOrderID,
      kioskSessionID,
      organizationID,
    });
  }
}
