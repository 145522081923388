import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import {PaymentService} from "../../../../core/services/api/modern/payment/payment.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../core/services/api/api.service";
import {IUserProfile} from "../../../../core/models/users/user-profile";
import {UtilsService} from "../../../../core/services/utils/utils.service";

@Component({
  selector: 'app-register-account',
  templateUrl: './register-account.component.html',
  styleUrls: ['./register-account.component.scss'],
})
export class RegisterAccountComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  formGroup: FormGroup;
  states : any = [];
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() email: string;
  @Input() address1: string;
  @Input() address2: string;
  @Input() city: string;
  @Input() state: string;
  @Input() zipCode: string;
  user: IUserProfile = {};

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    public modalCtrl: ModalController,
    private paymentService: PaymentService,
    private fb: FormBuilder,
    private utilsService : UtilsService,
  ) {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.address1 = '';
    this.address2 = '';
    this.city = '';
    this.state = '';
    this.zipCode = '';

    this.formGroup = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName : ['', [Validators.required]],
      email : ['', [Validators.required]],
      address1 : ['', [Validators.required]],
      address2 : [''],
      city : ['', [Validators.required]],
      state : ['', [Validators.required]],
      zipCode : ['', [Validators.required]],
    });
  }

  async ngOnInit(): Promise<void> {
    this.states = this.utilsService.getStates();
    this.context = await this.authService.getContext();
    const {data: userData} = await this.apiService.getV1('user', 'find', {id: this.context.UserID});
    this.user = userData['Data'];

  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  saveAccount() {
    let customerData = {
      FirstName : this.formGroup.get('firstName')?.value,
      LastName : this.formGroup.get('lastName')?.value,
      Address : {
        Address1 : this.formGroup.get('address1')?.value,
        Address2 : this.formGroup.get('address2')?.value,
        City : this.formGroup.get('city')?.value,
        StateProvinceRegion: this.formGroup.get('state')?.value,
        PostalCode : this.formGroup.get('zipCode')?.value,
      },
      Email : this.formGroup.get('email')?.value,
    }
    this.paymentService.registerDwollaCustomer(customerData, this.user.ID!, this.context.AccessToken!);
    this.modalCtrl.dismiss(true);
  }
}
