<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Contact Inviter</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-accordion-group>
    <ion-item *ngIf="this.inviter !== undefined">
      <ion-accordion value="first">
        <ion-item slot="header">
          <ion-label>{{this.inviter['Name']}}</ion-label>
        </ion-item>
        <div slot="content" *ngFor="let element of this.inviterContactPoints">
          <ion-label class="ion-padding">{{element["type"]}}: {{element["value"]}}</ion-label>
        </div>
      </ion-accordion>
    </ion-item>
  </ion-accordion-group>
</ion-content>
