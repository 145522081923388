<ion-header>
  <ion-toolbar class="toolbar" (click)="toggleExpanded()">
    <div id="container">
      <div id="header-container" *ngIf="context && context.ApplicationType">
        <ion-text class="school-name" *ngIf="context.ApplicationType !== 'Officials'">{{
          organizationName$ | async
        }}</ion-text>
        <div>
          <ion-text class="type-name" *ngIf="context.ApplicationType === 'Staff'" class="type-text" slot="end"
            >Staff</ion-text
          >
          <ion-text class="type-name" *ngIf="context.ApplicationType === 'Community'" class="type-text" slot="end"
            >Community</ion-text
          >
          <ion-text class="type-name" *ngIf="context.ApplicationType === 'Officials'" class="type-text" slot="end"
            >Officials</ion-text
          >
        </div>
      </div>
      <ion-icon name="chevron-up" class="dropdown-arrow" *ngIf="expanded"></ion-icon>
      <ion-icon name="chevron-down" class="dropdown-arrow" *ngIf="!expanded"></ion-icon>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="expanded" class="dropdown">
  <div class="list-container" *ngIf="showAccountPicker">
    <ion-item lines="full"><ion-label>Account</ion-label></ion-item>
    <ion-button
      expand="block"
      [fill]="context.ApplicationType === 'Staff' ? 'solid' : 'outline'"
      *ngIf="context.Staff"
      (click)="accountSelection('Staff')"
      [ngClass]="{
        selected: context.ApplicationType === 'Staff'
      }"
      >Staff <ion-icon name="chevron-forward" *ngIf="organizations && organizations.length > 0"></ion-icon
    ></ion-button>
    <ion-button
      [disabled]="organizations && organizations.length < 1"
      expand="block"
      *ngIf="context.Community"
      (click)="accountSelection('Community')"
      [fill]="context.ApplicationType === 'Community' ? 'solid' : 'outline'"
      [ngClass]="{
        selected: context.ApplicationType === 'Community'
      }"
      >Community <ion-icon name="chevron-forward" *ngIf="organizations && organizations.length > 0"></ion-icon
    ></ion-button>
    <ion-button
      expand="block"
      [fill]="context.ApplicationType === 'Officials' ? 'solid' : 'outline'"
      *ngIf="context.Official"
      (click)="accountSelection('Officials')"
      [ngClass]="{
        selected: context.ApplicationType === 'Officials'
      }"
      >Officials<ion-icon name="chevron-forward" *ngIf="organizations && organizations.length > 0"></ion-icon
    ></ion-button>
    <ion-item lines="full"><ion-label>Settings</ion-label></ion-item>
    <ion-button (click)="navigateTo('profile')" expand="block" fill="outline">Manage Profile</ion-button>
    <ion-button
      (click)="navigateTo('notifications')"
      expand="block"
      fill="outline"
      *ngIf="context.ApplicationType === 'Officials' && manageNotificationFeatureFlag"
      >Notifications</ion-button
    >
    <ion-button (click)="logOut()" expand="block" fill="outline"
      >Logout <ion-label *ngIf="context.Username">({{ context.Username }})</ion-label></ion-button
    >
  </div>
  <div class="list-container" *ngIf="!showAccountPicker">
    <ion-item lines="full"><ion-label>Account</ion-label></ion-item>
    <ion-button
      expand="block"
      fill="solid"
      *ngIf="context.Staff"
      (click)="showAccountPicker = !showAccountPicker"
      class="selected"
      ><ion-icon name="chevron-back"></ion-icon> {{ applicationType$ | async }}</ion-button
    >
    <ng-template ngFor let-orgs [ngForOf]="filteredOrganizations">
      <ion-button expand="block" fill="outline" (click)="setActiveOrganization(orgs)">{{
        orgs.OrganizationTitle
      }}</ion-button>
    </ng-template>
  </div>
</ion-content>
