import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import IContextModel from 'src/app/core/models/context/context.model';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UploadLogsService } from 'src/app/core/services/upload-logs/upload-logs.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-officials-footer',
  templateUrl: './officials-footer.component.html',
  styleUrls: ['./officials-footer.component.scss'],
})
export class OfficialsFooterComponent implements OnInit {
  context!: IContextModel;

  constructor(
    private authService: AuthService,
    private popoverController: PopoverController,
    private uploadLogsService: UploadLogsService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.context = await this.authService.getContext();
  }

  async dismissPopover() {
    await this.popoverController.dismiss();
  }

  async uploadLogs() {
    await this.uploadLogsService.uploadLogs();
    await this.popoverController.dismiss();
  }

  async payRouting(){
    await this.router.navigate(['/pay']);
    await this.popoverController.dismiss();
  }
}
