import { Component, NgZone, OnInit, ViewContainerRef } from '@angular/core';
import { AuthService } from './core/services/auth/auth.service';
import { ErrorService } from './core/services/error/error.service';
import { SuccessService } from './core/services/success/success.service';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { Network } from '@capacitor/network';
import { TicketTakersService } from './core/services/api/modern/ticket-takers/ticket-takers.service';
import { NGXLogger } from 'ngx-logger';
import { NgxLoggerMonitor } from './core/ngx/ngx-logger-monitor';
import { Capacitor } from '@capacitor/core';
import {IsAuthenticatedService} from "./core/services/auth/is-authenticated/is-authenticated.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isAuthenticated$ =  this.isAuthenticatedService.isAuthenticated$;

  applicationType$ = this.authService.applicationType$;

  constructor(
    private authService: AuthService,
    private errorService: ErrorService,
    private successService: SuccessService,
    private viewContainerRef: ViewContainerRef,
    private ticketTakersService: TicketTakersService,
    private ngZone: NgZone,
    private logger: NGXLogger,
    private isAuthenticatedService: IsAuthenticatedService
  ) {
    this.errorService.vcRef = this.viewContainerRef;
    this.successService.vcRef = this.viewContainerRef;

    this.logger.registerMonitor(new NgxLoggerMonitor());
  }

  async ngOnInit() {
    await this.purgeLogsCheck();
    try {
      await ScreenOrientation.lock({ orientation: 'portrait' });
    } catch (error) {
      this.logger.info('Error setting screen orientation.', error);
    }

    await Network.addListener('networkStatusChange', async (status) => {
      await this.ngZone.run(async () => {
        const scannedQRCodes = await this.authService.getScannedQRCodes();

        if (scannedQRCodes.length < 1) return;

        if (!status.connected) return;

        const ticketConfigurationIDs = [...new Set(scannedQRCodes.map((code) => code.TicketConfigurationID))];

        ticketConfigurationIDs.forEach(async (ticketConfigurationID) => {
          await this.ticketTakersService.submitScan(
            ticketConfigurationID,
            scannedQRCodes
              .filter((code) => code.TicketConfigurationID === ticketConfigurationID)
              .map((code) => {
                return { QrCodeValue: code.QrCodeValue, ScannedAt: code.ScannedAt };
              })
          );
        });

        await this.authService.deleteScannedQRCodes();
      });
    });
  }

  async purgeLogsCheck() {
    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'web') return;

    const logSizeInMegaBytes = (await this.authService.getTableSizeInBytes('Logs')) / Math.pow(1024, 2);

    if (logSizeInMegaBytes > 5) {
      this.logger.info('Purging logs');
      await this.authService.deleteLogs();
    }
  }
}
