import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root',
})
export class OfficialService {
  constructor(private apiService: ApiService) {}

  async update(userID: string, officialID: string, maxDistanceMiles: string, accessToken: string) {
    return await this.apiService.postV1(
      { UserID: userID, ID: officialID, MaxDistanceMiles: maxDistanceMiles },
      { m: 'Official', a: 'Update' },
      undefined,
      accessToken
    );
  }

  async getByUserID(userID: string, accessToken: string) {
    return await this.apiService.getV1('Official', 'GetByUserID', { id: userID }, accessToken);
  }

  async updateSport(userID: string, data: any, accessToken: string) {
    return await this.apiService.postV1(
      { ...data, UserID: userID, update: true },
      { m: 'Official', a: 'UpdateSport' },
      undefined,
      accessToken
    );
  }

  async createSport(
    officialsID: string,
    sportTitle: string,
    sportID: string,
    yearsOfExperience: number,
    accessToken: string
  ) {
    return await this.apiService.postV1(
      {
        OfficialID: officialsID,
        Sport: sportTitle,
        Ranking: 0,
        Licenses: [],
        SportID: sportID,
        YearsOfExperience: yearsOfExperience,
      },
      { m: 'Official', a: 'CreateSport' },
      undefined,
      accessToken
    );
  }

  async deleteSport(sportID: string) {
    return await this.apiService.postV1(undefined, {
      m: 'Official',
      a: 'DeleteSport',
      id: sportID,
    });
  }
}
