import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root',
})
export class KioskAccessService {
  private readonly controller = 'KioskAccess';

  constructor(private apiService: ApiService) {}

  getForUser(accessToken: string) {
    return this.apiService.get(this.controller, 'GetForUser', undefined);
  }
}
