import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';
import { IKioskCashSubmission } from 'src/app/core/models/kiosks/kiosk-cash-submission.model';
import { IKioskOrderRequest } from 'src/app/core/models/kiosks/kiosk-order-request.model';

@Injectable({
  providedIn: 'root',
})
export class KioskPurchasesService {
  private readonly controller = 'KioskPurchases';

  constructor(private apiService: ApiService) {}

  submitCash(input: IKioskCashSubmission) {
    return this.apiService.post(this.controller, 'SubmitCash', input);
  }

  startCheckout(input: IKioskOrderRequest) {
    return this.apiService.post(this.controller, 'StartCheckout', input);
  }

  sendReceipt(kioskSessionID: string, paymentIntentID: string, phoneNumber: string) {
    return this.apiService.post(this.controller, 'SendReceipt', undefined, {
      kioskSessionID,
      paymentIntentID,
      phoneNumber,
    });
  }
}
