import { Component, Input, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import {SuccessService} from "../../../../core/services/success/success.service";
import { MessageLegacyApiService } from '../../../../core/services/api/v1/MessageLegacyApi.service';
import { ErrorService } from '../../../../core/services/error/error.service';

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
})
export class ForgotUsernameComponent {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  @Input() email: string;

  constructor(
    private successService: SuccessService,
    private errorService: ErrorService,
    private messageLegacyApiService: MessageLegacyApiService,
    public modalCtrl: ModalController,
  ) {

    this.email = '';
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async submit() {
    if (!this.email) {
      // This probably isn't how this is supposed to be done, but it's an improvement on the nothing that's here currently
      this.errorService.showError("You must enter an email address.");
      return;
    }

    const response = await this.messageLegacyApiService.sendUsernameRetrievalEmail(this.email);
    if (response.isSuccess) {
      this.successService.showSuccess('An email has been sent with your username.', 5000, 'Email Sent');
      await this.modalCtrl.dismiss();
    }
    else if (response.isNotFound) {
      this.errorService.showError("That email address was not found.");
    }
  }
}
