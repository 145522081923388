import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'create-account',
    loadChildren: () =>
      import('./modules/create-account/create-account.module').then((m) => m.CreateAccountModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'calendar',
        loadChildren: () => import('./modules/calendar/calendar.module').then((m) => m.CalendarModule),
      },
      {
        path: 'tickets',
        loadChildren: () => import('./modules/tickets/tickets.module').then((m) => m.TicketsModule),
      },
      {
        path: 'messages',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'games',
        loadChildren: () => import('./modules/games/games.module').then((m) => m.GamesModule),
      },
      {
        path: 'blocks',
        loadChildren: () => import('./modules/blocks/blocks.module').then((m) => m.BlocksModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./modules/notifications/notifications.module').then((m) => m.NotificationsModule),
      },
      {
        path: 'kiosk',
        loadChildren: () => import('./modules/kiosk/kiosk.module').then((m) => m.KioskModule),
      },
      {
        path: 'pay',
        loadChildren: () => import('./modules/pay/pay.module').then((m) => m.PayModule),
      },
      {
        path: '',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      { path: '**', loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule) },
    ],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
