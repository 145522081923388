<ion-tabs> 
    <!-- This whole thing is placeholder -->
    <ion-tab-bar slot="home">
        <ion-tab-button tab="home">
          <div class="icon-container">
            <ion-img src="../../../../assets/images/appIcon/appicon.png" class="home-icon"></ion-img>
            <ion-text>Home</ion-text>
          </div>
        </ion-tab-button>
        <ion-tab-button tab="calendar">
            <div class="icon-container">
                <fa-icon icon="calendar-days" size="2x" class="icon"></fa-icon>
                Nothing
            </div>
        </ion-tab-button>
        <ion-tab-button tab="calendar">
            <div class="icon-container">
                <fa-icon icon="calendar-days" size="2x" class="icon"></fa-icon>
                Here
            </div>
        </ion-tab-button>
        <ion-tab-button tab="calendar">
            <div class="icon-container">
                <fa-icon icon="calendar-days" size="2x" class="icon"></fa-icon>
                Yet
            </div>
        </ion-tab-button>
    </ion-tab-bar>
</ion-tabs>
