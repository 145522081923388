import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private apiService: ApiService) {}

  async sendPasswordResetEmail(username: string) {
    return await this.apiService.postV1(
      undefined,
      {
        a: 'SendPasswordResetEmail',
        m: 'Message',
        username: username,
      },
    );
  }

  async sendUsernameRetrievalEmail(email: string){
    return await this.apiService.postV1( undefined,
      {
        m:'Message',a:'SendUsernameRetrievalEmail', email: email
      },
      );
  }
}
