import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root',
})
export class ContactPointService {
  constructor(private apiService: ApiService) {}

  async getByUserID(userID: string) {
    return await this.apiService.getV1('ContactPoint', 'GetByUserID', { userid: userID });
  }

  async update(userID: string, contact: any) {
    return await this.apiService.postV1(
      { ...contact, UserID: userID, update: true },
      {
        m: 'ContactPoint',
        a: 'Update',
      }
    );
  }

  async create(userID: string, contact: any) {
    return await this.apiService.postV1(
      {
        ...contact,
        UserID: userID,
        created: true,
      },
      {
        m: 'ContactPoint',
        a: 'Create',
      }
    );
  }

  async delete(contactID: string) {
    return await this.apiService.postV1(undefined, {
      m: 'ContactPoint',
      a: 'Delete',
      id: contactID,
    });
  }
}
