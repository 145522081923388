import { Component, Input, ViewChild } from '@angular/core';
import { IonModal, ModalController} from '@ionic/angular';
import {AuthService} from "../../../../core/services/auth/auth.service";
import {SuccessService} from "../../../../core/services/success/success.service";

interface ContactOption{
  ID: string;
  Title: string;
  ContactType: string;
}

@Component({
  selector: 'app-mfa-login',
  templateUrl: './mfa-login.component.html',
  styleUrls: ['./mfa-login.component.scss'],
})
export class MfaLoginComponent {
  @ViewChild(IonModal) modal!: IonModal;
  @Input() mfaCode: string;
  rememberDevice : boolean = false;
  contactPoints?: ContactOption[];
  sessionID! : string;
  sessionToken! : string;

  constructor(
    private successService: SuccessService,
    public modalCtrl: ModalController,
    private authService: AuthService
  ) {
    if (!this.sessionID) {
      this.sessionID = '';
    }
    if(!this.sessionToken){
      this.sessionToken = '';
    }
    this.mfaCode = '';
  }

  async startMultiFactorAuth(value : string){
    let promise = await this.authService.startMultiFactor(this.sessionID, this.sessionToken, value);

    if(promise.status >= 200 && promise.status < 300){
      this.successService.showSuccess('The code will arrive momentarily.', 5000, 'Code Sent');
    }
  }

  async verifyMultiFactorAuth()
  {
    let model = {
      SessionID: this.sessionID,
      SessionToken: this.sessionToken,
      VerificationCode: this.mfaCode,
      RememberDevice: this.rememberDevice,
    };
    let promise = await this.authService.verifyMultiFactor(model);

    await this.modalCtrl.dismiss(promise);
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

}
