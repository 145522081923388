import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AboundService } from '../../../../core/services/api/modern/abound/abound.service';
import { ApiService } from '../../../../core/services/api/api.service';
import { IUserProfile } from '../../../../core/models/users/user-profile';

@Component({
  selector: 'app-verify-password-tax',
  templateUrl: './verify-password-tax.component.html',
  styleUrls: ['./verify-password-tax.component.scss'],
})
export class VerifyPasswordTaxComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  formGroup: FormGroup;
  @Input() password: string;
  user: IUserProfile = {};

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    public modalCtrl: ModalController,
    private fb: FormBuilder,
    private aboundService: AboundService
  ) {
    this.password = '';
    this.formGroup = this.fb.group({
      password: ['', [Validators.required]],
    });
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
    const { data: userData } = await this.apiService.getV1('user', 'find', { id: this.context.UserID });
    this.user = userData['Data'];
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  async sendPassword() {
    const { data: returnData } = await this.aboundService.createDocumentsTokenForCurrentUser(
      this.formGroup.get('password')?.value
    );
    this.modalCtrl.dismiss(returnData);
  }
}
