import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import { AssignmentsService } from '../../../../core/services/api/legacy/assignments/assignments.service';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-decline-assignment',
  templateUrl: './decline-assignment.component.html',
  styleUrls: ['./decline-assignment.component.scss'],
})
export class DeclineAssignmentComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  @Input() reason: string;
  eventId: any;
  linkedId: any;
  openAssign: any;

  constructor(
    private authService: AuthService,
    public modalCtrl: ModalController,
    private assignmentsService: AssignmentsService
  ) {
    if (!this.eventId) {
      this.eventId = '';
    }

    if (!this.linkedId) {
      this.linkedId = false;
    }

    this.reason = '';
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  declineAssignment() {
      this.assignmentsService.declineAssignment(
        this.eventId,
        this.linkedId,
        this.reason,
        this.openAssign,
        this.context.AccessToken as string
      );
    this.modalCtrl.dismiss(this.eventId);
  }
}
