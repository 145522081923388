import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import IContextOrganizationModel from '../../../../../app/core/models/context/context-organization.models';
import IContextModel from '../../../../../../src/app/core/models/context/context.model';
import { AuthService } from '../../../../../../src/app/core/services/auth/auth.service';
import { FeatureFlagApiService } from '../../../../../../src/app/core/services/api/v2/FeatureFlagApi.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  applicationType$!: BehaviorSubject<string>;
  organizationName$: BehaviorSubject<string> = new BehaviorSubject<string>('No Organization');
  expanded: boolean = false;
  context!: IContextModel;
  showAccountPicker: boolean = true;
  organizations!: IContextOrganizationModel[];
  filteredOrganizations!: IContextOrganizationModel[];
  manageNotificationFeatureFlag: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly featureFlagApiService: FeatureFlagApiService
  ) {}

  async ngOnInit() {
    this.context = await this.authService.getContext();
    this.applicationType$ = new BehaviorSubject<string>(this.context.ApplicationType!);

    if (this.context.OrganizationId && this.context.OrganizationId !== 0) {
      this.organizations = (await this.authService.getContextOrganizations()).sort((a, b) =>
        a.OrganizationTitle!.localeCompare(b.OrganizationTitle!)
      );

      const organization = await this.authService.getContextOrganizationById(this.context.OrganizationId);

      let organizationName = organization ? organization.OrganizationTitle! : 'No Organization';
      this.organizationName$ = new BehaviorSubject<string>(organizationName);

      const enabledFeatureFlagResponse = await this.featureFlagApiService.getEnabled(organization.OrganizationId);

      if (enabledFeatureFlagResponse.isSuccess) {
        const enabledFeatureFlags = enabledFeatureFlagResponse.data;
        this.manageNotificationFeatureFlag = enabledFeatureFlags!.FeatureFlags!['manage-notifications'];
      }
    }
  }

  async accountSelection(accountSelection: string) {
    if (accountSelection === this.applicationType$.getValue()) {
      return;
    }

    this.applicationType$.next(accountSelection);

    this.filteredOrganizations =
      accountSelection === 'Staff'
        ? this.organizations.filter((org) => org.IsStaffOrganization)
        : this.organizations;

    if (accountSelection === 'Officials') {
      await this.completeContextChange(accountSelection);
      return;
    }

    if (!this.filteredOrganizations) {
      await this.completeContextChange(accountSelection);

      return;
    }

    this.showAccountPicker = false;
  }

  async setActiveOrganization(organization: IContextOrganizationModel) {
    await this.completeContextChange(this.applicationType$.getValue(), organization);
  }

  async completeContextChange(applicationType: string, organization?: IContextOrganizationModel) {
    this.context.ApplicationType = applicationType;
    this.authService.applicationType$.next(applicationType);

    if (organization) {
      this.context.OrganizationId = organization.ID;
      this.organizationName$.next(organization.OrganizationTitle!);
    }

    await this.authService.updateContext(this.context);
    this.router.navigate(['/']);
    this.toggleExpanded();
  }

  toggleExpanded() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      // Reset menu
      this.showAccountPicker = true;
      this.applicationType$.next(this.context.ApplicationType!);
    }
  }

  navigateTo(flag: string) {
    this.expanded = false;
    this.router.navigate([flag]);
  }

  logOut() {
    this.authService.deleteContext();
    this.router.navigate(['/login']);
  }
}
