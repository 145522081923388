import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import {AssignmentsService} from "../../../../core/services/api/legacy/assignments/assignments.service";
import {UtilsService} from "../../../../core/services/utils/utils.service";

@Component({
  selector: 'app-linked-events',
  templateUrl: './linked-events.component.html',
  styleUrls: ['./linked-events.component.scss'],
})
export class LinkedEventsComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  linkedId: any;
  linkedGames : any =[];
  constructor(
    private readonly authService: AuthService,
    public readonly modalCtrl: ModalController,
    private readonly assignmentsService: AssignmentsService,
    private readonly utilsService: UtilsService
  ) {

    if (!this.linkedId) {
      this.linkedId = false;
    }
  }
  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
    let {data: gamesData} = await this.assignmentsService.getLinked(this.linkedId)
    if(gamesData && gamesData["Data"]) {
      this.linkedGames = gamesData["Data"];
    }
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  fixStartDateTime(dateTime:string){
      return this.utilsService.getDateTime(dateTime, this.context.TimeZone!);
  }
}

