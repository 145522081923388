import { Component, Input, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import {MessageService} from "../../../../core/services/api/legacy/message/message.service";
import {SuccessService} from "../../../../core/services/success/success.service";

@Component({
  selector: 'app-forgot-username',
  templateUrl: './forgot-username.component.html',
})
export class ForgotUsernameComponent {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  @Input() email: string;

  constructor(
    private successService: SuccessService,
    private messageService : MessageService,
    public modalCtrl: ModalController,
  ) {

    this.email = '';
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async submit() {
    let promise = await this.messageService.sendUsernameRetrievalEmail(this.email);

    if(promise.status >= 200 && promise.status < 300){
      this.successService.showSuccess('An email has been sent with your username.', 5000, 'Email Sent');
    }

    this.modalCtrl.dismiss();
  }
}
