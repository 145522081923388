export class ContextUpgradeStatements {
  upgrades = [
    {
      toVersion: 1,
      statements: [
        `CREATE TABLE IF NOT EXISTS 
        Context (ID INTEGER PRIMARY KEY NOT NULL, ApplicationType TEXT DEFAULT '', Community BOOLEAN NOT NULL CHECK (Community IN (0, 1)), Staff BOOLEAN NOT NULL CHECK (Staff IN (0, 1)), 
        Official BOOLEAN NOT NULL CHECK (Official IN (0, 1)), UserToken TEXT DEFAULT '', AccessToken TEXT DEFAULT '', TokenType TEXT DEFAULT '', RefreshExpiresIn INTEGER DEFAULT 1, RefreshToken TEXT DEFAULT '', Username TEXT DEFAULT '', FirstName TEXT DEFAULT '', 
        LastName TEXT DEFAULT '', UserID TEXT DEFAULT '', TimeZone TEXT DEFAULT '', DeviceTicket TEXT DEFAULT '', DefaultApplication TEXT DEFAULT '', OrganizationId INTEGER DEFAULT 0);`,
        `CREATE TABLE IF NOT EXISTS 
        ContextOrganizations (ID INTEGER PRIMARY KEY NOT NULL, OrganizationId TEXT DEFAULT '', IsStaffOrganization BOOLEAN NOT NULL CHECK (IsStaffOrganization IN (0, 1)) ,OrganizationTimezone TEXT DEFAULT '', OrganizationTitle TEXT DEFAULT '', 
        OrganizationDisplayTitle TEXT DEFAULT '');`,
        `CREATE TABLE IF NOT EXISTS 
        TransactionHistory (ID INTEGER PRIMARY KEY NOT NULL, Date TEXT DEFAULT '', Time TEXT DEFAULT '', Title TEXT DEFAULT '', TicketQuantity INTEGER DEFAULT 0, 
        EventTitle TEXT DEFAULT '', Total TEXT DEFAULT '', IsPhoneNumberAccepted BOOLEAN NOT NULL CHECK (IsPhoneNumberAccepted IN (0, 1)), PaymentIntentID TEXT DEFAULT '', TicketConfigurationID INTEGER DEFAULT 1);`,
        `CREATE TABLE IF NOT EXISTS ScannedQRCodes (ID INTEGER PRIMARY KEY NOT NULL, QrCodeValue TEXT DEFAULT '', ScannedAt TEXT DEFAULT '', TicketConfigurationID TEXT DEFAULT '');`,
        `CREATE TABLE IF NOT EXISTS 
        Kiosk (ID INTEGER PRIMARY KEY NOT NULL, KioskSessionID INTEGER DEFAULT 0, SelectedKioskID INTEGER DEFAULT 0, CashBoxAmount INTEGER DEFAULT 0);`,
        `CREATE TABLE IF NOT EXISTS 
        Logs (ID INTEGER PRIMARY KEY NOT NULL, DateTime TEXT DEFAULT '', Message TEXT DEFAULT '', Method TEXT DEFAULT '', File TEXT DEFAULT '', UserID TEXT DEFAULT '');`,
      ],
    },
  ];
}
