import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@capacitor/core';
import { V2ApiService } from './bases/v2-api.service';
import { LegacyApiService } from './bases/legacy-api.service';

/** DEPRECATED */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private v2Api: V2ApiService,
    private legacyApi: LegacyApiService
  ) {
  }

  /** DEPRECATED - You should be using an API service (e.g. LevelsApiService) if calling an API or V2ApiService if you're building an API service */
  async get(
    controller: string,
    action: string,
    params?: HttpParams
  ) {
    const response = await this.v2Api.get<any>(controller, action, params);

    // Callers previously always assumed data to be non-null anyway
    return { data: response.data! };
  }

  /** DEPRECATED - You should be using an API service (e.g. LevelsApiService) if calling an API or V2ApiService if you're building an API service */
  async post( 
    controller: string,
    action: string,
    body?: any,
    params?: HttpParams
  ) {
    const response = await this.v2Api.post<any>(controller, action, params, body);

    // Callers previously always assumed data to be non-null anyway
    return { data: response.data!, status: response.statusCode };
  }

  /** DEPRECATED - You should be using an API service (e.g. LevelsLegacyApiService) if calling an API or LegacyApiService if you're building an API service */
  async getV1(controller: string, action: string, params?: any, accessToken?: string) {
    const response = await this.legacyApi.get<any>(controller, action, params);

    // Callers previously always assumed data to be non-null anyway
    return { data: { Data: response.data! } };
  }

  /** DEPRECATED - You should be using an API service (e.g. LevelsLegacyApiService) if calling an API or LegacyApiService if you're building an API service */
  async postV1(
    body?: any,
    params?: HttpParams,
    headers?: HttpHeaders,
    accessToken?: string
  ) {
    // These were being set in every call. If params was null or "m"/"a" were missing then the API call didn't work previously anyway since those are required
    const controller = params!["m"] as string;
    const action = params!["a"] as string;
    const response = await this.legacyApi.post<any>(controller, action, params, body);

    // Callers previously always assumed data to be non-null anyway
    return { data: { Data: response.data!, Error: response.errorMessage! }, status: response.statusCode };
  }
}
