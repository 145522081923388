<ion-header xmlns="http://www.w3.org/1999/html">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title style="text-align: start"> Verify Micro Deposits</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-top">
  <ion-item>
    <ion-text style="width: 100%;">
      Please enter the two deposit amounts found in your bank account that are less than or equal to $0.10.
    </ion-text>
  </ion-item>
  <ion-item>
    <ion-text>
      After 3 incorrect attempts, this account will be removed and the process will need to be started again from the beginning.
      Do not attempt to guess the deposit amounts. Please contact Eventlink Customer Success &#64; (866) 330-7710 if you have any questions before proceeding.
    </ion-text>
  </ion-item>
  <form [formGroup]="formGroup">
    <ion-text class="ion-padding-start text-start top-line text-top-margin"> Amount:</ion-text>
    <ion-item class="show-inputs background-transparent p3-padding">
      <ion-text style="width: 10%">$</ion-text><ion-input style="width: 30%" formControlName="amount1" type="number"></ion-input><ion-text style="width: 15%"> and $</ion-text><ion-input style="margin-left: 5px; width: 40%" formControlName="amount2" type="number"></ion-input>
    </ion-item>
  </form>
  <ion-buttons class="ion-padding">
    <ion-button
      class="terms-button"
      type="submit"
      appPreventMultiClickEvents
      (preventMultiClicks)="submit()"
      expand="block"
      [disabled]="!formGroup.valid">
      <ion-text> Verify </ion-text>
    </ion-button>
  </ion-buttons>
</ion-content>
