import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { Injectable } from '@angular/core';
import IContextModel from '../models/context/context.model';
import { GlobalErrorHandlerService } from '../services/global-error-handler/global-error-handler.service';
import IContextOrganizationModel from '../models/context/context-organization.models';
import ITransactionHistoryModel from '../models/context/transaction-history/transaction-history.model';
import IKioskSessionContext from '../models/context/kiosk/kiosk-session-context.model';
import { ITicketTakerOfflineScan } from '../models/ticket-takers/ticket-taker-offline-scan.model';
import ILogEntry from '../models/context/logs/log-entry.model';

@Injectable({
  providedIn: 'root',
})
export class ContextRepository {
  private db!: SQLiteDBConnection;

  constructor(private globalErrorHandlerService: GlobalErrorHandlerService) {}

  async initRepository(db: SQLiteDBConnection) {
    this.db = db;
  }

  async getContext(ID: number = 1) {
    let sqlcmd: string = 'SELECT * FROM Context WHERE ID = ?;';
    let values: Array<any> = [ID];

    let ret: any = await this.db.query(sqlcmd, values);

    if (!ret.values) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - getContext(): get context failed')
      );

      return {} as IContextModel;
    }

    return ret.values[0] as IContextModel;
  }

  async getContextOrganizations() {
    let ret: any = await this.db.query('SELECT * FROM ContextOrganizations');

    if (!ret.values) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - getContextOrganizationByID(): get context organization failed')
      );

      return [] as IContextOrganizationModel[];
    }

    return ret.values as IContextOrganizationModel[];
  }

  async getContextOrganizationByID(ID: number) {
    let sqlcmd: string = 'SELECT * FROM ContextOrganizations WHERE ID = ?;';
    let values: Array<any> = [ID];

    let ret: any = await this.db.query(sqlcmd, values);

    if (!ret.values) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - getContextOrganizationByID(): get context organization failed')
      );

      return {} as IContextOrganizationModel;
    }

    return ret.values[0] as IContextOrganizationModel;
  }

  async getContextOrganizationByOrganizationID(organizationID: string) {
    let sqlcmd: string = 'SELECT * FROM ContextOrganizations WHERE OrganizationId = ?;';
    let values: Array<any> = [organizationID];

    let ret: any = await this.db.query(sqlcmd, values);

    if (!ret.values) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - getContextOrganizationByID(): get context organization failed')
      );

      return {} as IContextOrganizationModel;
    }

    return ret.values[0] as IContextOrganizationModel;
  }

  async insertContext(context: IContextModel) {
    let sqlcmd: string = `INSERT INTO Context (AccessToken, TokenType, UserID, UserToken, Username, FirstName, LastName, TimeZone,
      DefaultApplication, Community, Staff, Official, DeviceTicket, RefreshToken, RefreshExpiresIn)
      VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?);`;

    let values: Array<any> = [
      context.AccessToken,
      context.TokenType,
      context.UserID,
      context.UserToken,
      context.Username,
      context.FirstName,
      context.LastName,
      context.TimeZone,
      context.DefaultApplication,
      context.Community,
      context.Staff,
      context.Official,
      context.DeviceTicket,
      context.RefreshToken,
      context.RefreshExpiresIn,
    ];

    let ret: any = await this.db.run(sqlcmd, values);

    if (ret.changes.changes <= 0) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - insertContext(): inserting context failed')
      );
    }
  }

  async insertContextOrganization(contextOrganization: IContextOrganizationModel) {
    let sqlcmd: string = `INSERT INTO ContextOrganizations (OrganizationId, OrganizationTimezone, OrganizationTitle, OrganizationDisplayTitle, IsStaffOrganization)
      VALUES(?, ?, ?, ?, ?);`;

    let values: Array<any> = [
      contextOrganization.OrganizationId,
      contextOrganization.OrganizationTimezone,
      contextOrganization.OrganizationTitle,
      contextOrganization.OrganizationDisplayTitle,
      contextOrganization.IsStaffOrganization,
    ];
    let ret: any = await this.db.run(sqlcmd, values);

    if (ret.changes.changes <= 0) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - insertContextOrganization(): inserting context organization failed')
      );
    }
  }

  async updateContext(context: IContextModel) {
    let sqlcmd: string = `UPDATE Context SET AccessToken = ?, TokenType = ?, UserID = ?, UserToken = ?, Username = ?,
    FirstName = ?, LastName = ?, TimeZone = ?, DefaultApplication = ?, ApplicationType = ?, Community = ?, Staff = ?, Official = ?,
    DeviceTicket = ?, RefreshToken = ?, RefreshExpiresIn = ?, OrganizationId = ? WHERE ID = ?;`;

    let values: Array<any> = [
      context.AccessToken,
      context.TokenType,
      context.UserID,
      context.UserToken,
      context.Username,
      context.FirstName,
      context.LastName,
      context.TimeZone,
      context.DefaultApplication,
      context.ApplicationType,
      context.Community,
      context.Staff,
      context.Official,
      context.DeviceTicket,
      context.RefreshToken,
      context.RefreshExpiresIn,
      context.OrganizationId,
      context.ID,
    ];

    let ret: any = await this.db.run(sqlcmd, values);

    if (ret.changes.changes <= 0) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - updateContext(): updating context failed')
      );
    }
  }

  async updateContextOrganization(contextOrganization: IContextOrganizationModel) {
    let sqlcmd: string = `UPDATE ContextOrganizations SET OrganizationId = ?, OrganizationTimezone = ?, OrganizationTitle = ?, OrganizationDisplayTitle = ?, IsStaffOrganization = ? WHERE ID = ?;`;

    let values: Array<any> = [
      contextOrganization.OrganizationId,
      contextOrganization.OrganizationTimezone,
      contextOrganization.OrganizationTitle,
      contextOrganization.OrganizationDisplayTitle,
      contextOrganization.IsStaffOrganization,
      contextOrganization.ID,
    ];

    let ret: any = await this.db.run(sqlcmd, values);

    if (ret.changes.changes <= 0) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - updateContextOrganization(): updating context organization failed')
      );
    }
  }

  async deleteContext() {
    await this.db.query('DELETE FROM Context;');
  }

  async deleteContextOganizations() {
    await this.db.query('DELETE FROM ContextOrganizations;');
  }

  async getTransactionHistory() {
    let sqlcmd: string = 'SELECT * FROM TransactionHistory ORDER BY Date ASC, Time ASC;';

    let ret: any = await this.db.query(sqlcmd);

    if (!ret.values) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - getTransactionHistory(): get transaction history failed')
      );

      return [];
    }

    return ret.values as ITransactionHistoryModel[];
  }

  async insertTransaction(transaction: ITransactionHistoryModel) {
    let sqlcmd: string = `INSERT INTO TransactionHistory (Date, Time, Title, EventTitle, Total, IsPhoneNumberAccepted, PaymentIntentID, TicketConfigurationID, TicketQuantity)
      VALUES(?, ?, ?, ?, ?, ?, ?, ?, ?);`;

    let values: Array<any> = [
      transaction.Date,
      transaction.Time,
      transaction.Title,
      transaction.EventTitle,
      transaction.Total,
      transaction.IsPhoneNumberAccepted,
      transaction.PaymentIntentID,
      transaction.TicketConfigurationID,
      transaction.TicketQuantity,
    ];

    let ret: any = await this.db.run(sqlcmd, values);

    if (ret.changes.changes <= 0) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - insertTransaction(): inserting transaction failed')
      );
    }
  }

  async updateTransaction(transaction: ITransactionHistoryModel) {
    let sqlcmd: string = `UPDATE TransactionHistory SET Date = ?, Time = ?, Title = ?, EventTitle = ?, Total = ?, IsPhoneNumberAccepted = ?, PaymentIntentID = ?, TicketConfigurationID = ?, TicketQuantity = ? WHERE ID = ?;`;

    let values: Array<any> = [
      transaction.Date,
      transaction.Time,
      transaction.Title,
      transaction.EventTitle,
      transaction.Total,
      transaction.IsPhoneNumberAccepted,
      transaction.PaymentIntentID,
      transaction.TicketConfigurationID,
      transaction.TicketQuantity,
      transaction.ID,
    ];

    let ret: any = await this.db.run(sqlcmd, values);

    if (ret.changes.changes <= 0) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - updateTransaction(): updating transaction failed')
      );
    }
  }

  async deleteOldestTransactionHistory() {
    await this.db.query(
      'DELETE FROM TransactionHistory WHERE Date IN (SELECT MIN(Date) FROM TransactionHistory) AND Time IN (SELECT MIN(Time) FROM TransactionHistory WHERE Date IN (SELECT MIN(Date) FROM TransactionHistory));'
    );
  }

  async deleteTransactionHistory() {
    await this.db.query('DELETE FROM TransactionHistory;');
  }

  async getKiosk(ID: number = 1) {
    let sqlcmd: string = 'SELECT * FROM Kiosk WHERE ID = ?;';
    let values: Array<any> = [ID];

    let ret: any = await this.db.query(sqlcmd, values);

    if (!ret.values) {
      this.globalErrorHandlerService.handleError(new Error('ContextRepository - getKiosk(): get kiosk failed'));

      return {} as IKioskSessionContext;
    }

    return ret.values[0] as IKioskSessionContext;
  }

  async insertKiosk(context: IKioskSessionContext) {
    let sqlcmd: string = `INSERT INTO Kiosk (KioskSessionID, SelectedKioskID, CashBoxAmount)
      VALUES(?, ?, ?);`;

    let values: Array<any> = [context.KioskSessionID, context.SelectedKioskID, context.CashBoxAmount];

    let ret: any = await this.db.run(sqlcmd, values);

    if (ret.changes.changes <= 0) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - insertKiosk(): inserting kiosk failed')
      );
    }
  }

  async updateKiosk(context: IKioskSessionContext) {
    let sqlcmd: string = `UPDATE Kiosk SET KioskSessionID = ?, SelectedKioskID = ?, CashBoxAmount = ? WHERE ID = ?;`;

    let values: Array<any> = [context.KioskSessionID, context.SelectedKioskID, context.CashBoxAmount, context.ID];

    let ret: any = await this.db.run(sqlcmd, values);

    if (ret.changes.changes <= 0) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - updateKiosk(): updating kiosk failed')
      );
    }
  }

  async deleteKiosk() {
    await this.db.query('DELETE FROM Kiosk;');
  }

  async getScannedQRCodes() {
    let ret: any = await this.db.query('SELECT * FROM ScannedQRCodes');

    if (!ret.values) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - getScannedQRCodes(): get scanned qr codes failed')
      );

      return [] as ITicketTakerOfflineScan[];
    }

    return ret.values as ITicketTakerOfflineScan[];
  }

  async insertScannedQRCode(ticketScan: ITicketTakerOfflineScan) {
    let sqlcmd: string = `INSERT INTO ScannedQRCodes (QrCodeValue, ScannedAt, TicketConfigurationID)
      VALUES(?, ?, ?);`;

    let values: Array<any> = [ticketScan.QrCodeValue, ticketScan.ScannedAt, ticketScan.TicketConfigurationID];

    let ret: any = await this.db.run(sqlcmd, values);

    if (ret.changes.changes <= 0) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - insertScannedQRCode(): inserting qr code failed')
      );
    }
  }

  async deleteScannedQRCodes() {
    await this.db.query('DELETE FROM ScannedQRCodes;');
  }

  async getLogEntries() {
    let ret: any = await this.db.query('SELECT * FROM Logs');

    if (!ret.values) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - getLogEntries(): get logged entries failed')
      );

      return [] as ILogEntry[];
    }

    return ret.values as ILogEntry[];
  }

  async insertLogEntry(logEntry: ILogEntry) {
    let sqlcmd: string = `INSERT INTO Logs (DateTime, Message, Method, File, UserID) VALUES(?, ?, ?, ?, ?);`;

    let values: Array<any> = [
      logEntry.DateTime.toString(),
      logEntry.Message,
      logEntry.Method,
      logEntry.File,
      logEntry.UserID,
    ];

    let ret: any = await this.db.run(sqlcmd, values);

    if (ret.changes.changes <= 0) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - insertLogEntry(): inserting log failed')
      );
    }
  }

  async deleteLogEntries() {
    await this.db.query('DELETE FROM Logs;');
  }

  async getTableSizeInBytes(tableName: string) {
    let sqlcmd: string = `SELECT SUM("pgsize") FROM "dbstat" WHERE name='${tableName}';`;

    let ret: any = await this.db.query(sqlcmd);

    if (!ret.values) {
      this.globalErrorHandlerService.handleError(
        new Error('ContextRepository - getTableSizeInBytes(): get table size failed')
      );

      return 0;
    }

    return ret.values[0] as number;
  }
}
