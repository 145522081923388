import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';
import { IUserProfile } from 'src/app/core/models/users/user-profile';

@Injectable({
  providedIn: 'root',
})
export class UserLegacyService {
  constructor(private apiService: ApiService) {}

  async getOrganizationSubscriptions(userID: string, accessToken: string) {
    return await this.apiService.getV1('User', 'GetOrganizationSubscriptions', { id: userID }, accessToken);
  }
  async acceptTermsOfService(accessToken: string) {
    await this.apiService.postV1(
      undefined,
      {
        m:'User',
        a:'AcceptTermsOfService',
      },
      undefined,
      accessToken
    );
  }
  async updateCurrent(user: IUserProfile, accessToken: string) {
    return await this.apiService.postV1(user, { m: 'User', a: 'UpdateCurrent' }, undefined, accessToken);
  }

  async find(userID: string) {
    return await this.apiService.getV1('User', 'Find', { id: userID });
  }
}
