import { FormsModule } from '@angular/forms';
import { MbscModule } from '@mobiscroll/angular';
import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {
  faCalendarDays,
  faTicket,
  faEnvelope,
  faPersonRunning,
  faBars,
  faQrcode,
  faListUl,
  faBlockQuestion,
  faCameraViewfinder,
  faMoneyBill1,
  faBatteryLow,
  faBatteryQuarter,
  faBatteryHalf,
  faBatteryThreeQuarters,
  faBatteryFull,
  faCreditCard,
  faShare,
  faBan,
  faCirclePlus,
  faCircleMinus,
  faTrash,
  faStore,
  faSquareQuestion,
  faPlus,
  faCopy,
  faCheck,
  faEllipsisVertical,
  faWheelchair,
  faUserCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoggerModule } from 'ngx-logger';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GlobalErrorHandlerService } from './core/services/global-error-handler/global-error-handler.service';
import { environment } from '../environments/environment';
import { InitializeAppService } from './core/services/initalize/initialize.app.service';
import { ApiInterceptor } from './core/interceptors/api/api.interceptor';
import { SharedModule } from './shared/shared.module';

export function initializeFactory(init: InitializeAppService) {
  return () => init.initializeApp();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    FormsModule,
    MbscModule,
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
      innerHTMLTemplatesEnabled: true,
    }),
    AppRoutingModule,
    HttpClientModule,
    LoggerModule.forRoot({
      serverLoggingUrl: environment.logging.url,
      level: environment.logging.logLevel,
      serverLogLevel: environment.logging.serverLogLevel,
      disableConsoleLogging: false,
    }),
    FontAwesomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SharedModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ApiInterceptor, useClass: ApiInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFactory,
      deps: [InitializeAppService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faCalendarDays,
      faTicket,
      faEnvelope,
      faBars,
      faQrcode,
      faListUl,
      faBlockQuestion,
      faCameraViewfinder,
      faMoneyBill1,
      faUserCheck,
      faBatteryLow,
      faBatteryQuarter,
      faBatteryHalf,
      faBatteryThreeQuarters,
      faBatteryFull,
      faCreditCard,
      faShare,
      faBan,
      faSquareQuestion,
      faPersonRunning,
      faCirclePlus,
      faCircleMinus,
      faTrash,
      faStore,
      faWheelchair,
      faPlus,
      faCopy,
      faEllipsisVertical,
      faCheck
    );
  }
}
