import { Component, Input, ViewChild } from '@angular/core';
import { AlertController, IonModal, ModalController } from '@ionic/angular';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { ITicketCheckoutAvailabilityResponse } from 'src/app/core/models/ticket-purchases/ticket-checkout-availability-response.model';
import { ITicketCheckoutRequest } from 'src/app/core/models/ticket-purchases/ticket-checkout-request.model';
import { ITicketCheckoutResponse } from 'src/app/core/models/ticket-purchases/ticket-checkout-response.model';
import { TicketPurchasesService } from 'src/app/core/services/api/modern/ticket-purchases/ticket-purchases.service';
import { StripeTerminal } from '@capacitor-community/stripe-terminal';
import { ErrorService } from 'src/app/core/services/error/error.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout-modal.component.html',
  styleUrls: ['./checkout-modal.component.scss'],
})
export class CheckoutComponent {
  @ViewChild(IonModal) modal!: IonModal;
  @Input() checkoutRequest!: ITicketCheckoutRequest;
  @Input() accessToken!: string;

  sendReceiptPhoneNumber: string = '';
  checkoutResponse!: ITicketCheckoutResponse;
  title: string = 'Send Receipt';

  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private ticketPurchasesService: TicketPurchasesService,
    private errorService: ErrorService,
    private authService: AuthService
  ) {}

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async skipPhoneNumber() {
    await this.checkout();
  }

  async sendPhoneNumber() {
    if (!isValidPhoneNumber('+1 ' + this.sendReceiptPhoneNumber)) {
      const alert = await this.alertController.create({
        header: 'Invalid Phone Number',
        message: 'Please enter a valid phone number.',
        buttons: ['OK'],
      });

      await alert.present();

      return;
    }

    this.checkoutRequest.ReceiptPhoneNumber = this.sendReceiptPhoneNumber;

    await this.checkout();
  }

  async checkout() {
    this.title = 'Checkout';

    const { data: ticketCheckoutRequestData }: { data: ITicketCheckoutResponse } =
      await this.ticketPurchasesService.startCheckout(this.checkoutRequest);

    if (!ticketCheckoutRequestData || !ticketCheckoutRequestData.StripePaymentIntentID) {
      return this.modalCtrl.dismiss(
        {
          success: false,
          phoneNumber: '',
          paymentIntentID: '',
        },
        'cancel'
      );
    }

    const { data: confirmAvailabilityData }: { data: ITicketCheckoutAvailabilityResponse } =
      await this.ticketPurchasesService.confirmAvailability(ticketCheckoutRequestData.StripePaymentIntentID);

    this.checkoutResponse = ticketCheckoutRequestData;

    const context = await this.authService.getContext();

    if (!confirmAvailabilityData.Valid) {
      await this.authService.insertLog({
        Message: `'Transaction Error': ${confirmAvailabilityData.Message}`,
        DateTime: new Date(),
        UserID: context.UserID!,
      });

      return this.errorService.showError(confirmAvailabilityData.Message, 3000, 'Transaction Error');
    }
  }

  async swipeCard() {
    const alert = await this.alertController.create({
      header: 'Transaction in Progress',
      message: 'Please swipe, tap, or insert your card to complete the transaction.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          handler: async () => {
            await StripeTerminal.cancelCollectPaymentMethod();
            return this.modalCtrl.dismiss(
              {
                success: false,
                phoneNumber: '',
                paymentIntentID: '',
              },
              'cancel'
            );
          },
        },
      ],
    });

    await alert.present();

    await StripeTerminal.collectPaymentMethod({
      paymentIntent: this.checkoutResponse.PaymentIntentClientSecret,
    });

    await StripeTerminal.confirmPaymentIntent();

    await alert.dismiss();

    return this.modalCtrl.dismiss(
      {
        success: true,
        phoneNumber: this.sendReceiptPhoneNumber,
        paymentIntentID: this.checkoutResponse.StripePaymentIntentID,
      },
      'confirm'
    );
  }

  formatCurrency(amount: number) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return formatter.format(amount);
  }
}
