import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import {PaymentService} from "../../../../core/services/api/modern/payment/payment.service";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-verify-micro-deposits',
  templateUrl: './verify-micro-deposits.component.html',
  styleUrls: ['./verify-micro-deposits.component.scss'],
})
export class VerifyMicroDepositsComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  formGroup: FormGroup;
  @Input() dwollaID: string = '';
  @Input() amount1?: number;
  @Input() amount2?: number;

  constructor(
    private authService: AuthService,
    public modalCtrl: ModalController,
    private paymentService: PaymentService,
    private fb: FormBuilder,
  ) {
    this.formGroup = this.fb.group({
      amount1 : [undefined],
      amount2 : [undefined],
    });
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  async submit() {
    this.paymentService.verifyFundingSources(this.formGroup.get('amount1')?.value, this.formGroup.get('amount2')?.value, this.dwollaID)
    this.modalCtrl.dismiss(true);
  }
}
