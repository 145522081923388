import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';
import { ICashTicketRequest } from 'src/app/core/models/ticket-purchases/cash-ticket-request.model';
import { ITicketCheckoutRequest } from 'src/app/core/models/ticket-purchases/ticket-checkout-request.model';

@Injectable({
  providedIn: 'root',
})
export class TicketPurchasesService {
  private readonly controller = 'TicketPurchases';

  constructor(private apiService: ApiService) {}

  submitCash(cashTicketRequests: ICashTicketRequest[], ticketConfigurationID: string) {
    return this.apiService.post(this.controller, 'SubmitCash', cashTicketRequests, {
      ticketConfigurationID,
    });
  }

  confirmAvailability(stripePaymentIntentID: string) {
    return this.apiService.post(this.controller, 'ConfirmAvailability', {}, { stripePaymentIntentID });
  }

  startCheckout(ticketCheckoutRequest: ITicketCheckoutRequest) {
    return this.apiService.post(this.controller, 'StartCheckout', ticketCheckoutRequest);
  }
}
