import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';
import { ITicketTakerScan } from 'src/app/core/models/ticket-takers/ticket-taker-scan.model';
import { HttpParams } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class TicketTakersService {
  private readonly controller = 'TicketTakers';

  constructor(private apiService: ApiService) {}

  getAvailableEventsForCurrentUser() {
    return this.apiService.get(this.controller, 'GetAvailableEventsForCurrentUser');
  }

  submitScan(ticketConfigurationID: string, scanInputs: ITicketTakerScan[]) {
    return this.apiService.post(this.controller, 'SubmitScan', scanInputs, {
      ticketConfigurationID,
    });
  }

  resendSmsReceipt(paymentIntentID: string, ticketConfigurationID: string, overridePhoneNumber?: string) {
    const params: HttpParams = { paymentIntentID, ticketConfigurationID };

    if (overridePhoneNumber !== undefined) {
      params.overridePhoneNumber = overridePhoneNumber;
    }

    return this.apiService.post(this.controller, 'ResendSmsReceipt', {}, params);
  }
}
