import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private readonly controller = 'Payment';

  constructor(private apiService: ApiService) {
  }

  async verifyFundingSources(amount1: number, amount2: number, accountID: string){

    let amounts = {Amount1: amount1.toString(), Amount2: amount2.toString()};
    await this.apiService.postV1(
        amounts,
      {
        m: 'Payment',
        a:'VerifyFundingSourceMicroDeposits',
        id:accountID
      }
    );
  }

  async updateDwollaCustomer(customer: any, customerId: string, accessToken: string) {
    await this.apiService.postV1(
      customer,
      {
        m: this.controller,
        a: 'UpdateCustomerForUser',
        id: customerId,
      },
      undefined,
      accessToken
    );
  }

  async registerDwollaCustomer(customer: any, customerId: string, accessToken: string) {
    let customerData = {
      FirstName: customer.FirstName,
      LastName: customer.LastName,
      Address: {
        Address1: customer.Address.Address1,
        Address2: customer.Address.Address2,
        City: customer.Address.City,
        StateProvinceRegion: customer.Address.State,
        PostalCode: customer.Address.Zipcode,
      },
      Email: customer.Email,
    };
    await this.apiService.postV1(
      customerData,
      {
        m: this.controller,
        a: 'RegisterCustomerForUser',
        id: customerId,
      },
      undefined,
      accessToken
    );
  }

  async getTransfersForCustomer(customer: any, startDate: any, endDate: any, accessToken: string) {
    return this.apiService.getV1(
      this.controller,
      'GetTransfersForCustomer',
      {
        id: customer,
        StartDate: startDate,
        EndDate: endDate,
      },
      accessToken
    );
  }

  async getDwollaCustomer(customerId: string, accessToken: string) {
    return this.apiService.getV1(this.controller, 'GetCustomerForUser', {id: customerId}, accessToken);
  }

  async getByUserID(userID: string) {
    return this.apiService.get(this.controller, 'GetByUserID', {userID});
  }

  async getFundingSourcesByUserID(userId: string, accessToken: string) {
    return this.apiService.getV1(
      this.controller,
      'GetFundingSourcesForCustomer',
      {
        id: userId,
        removed: false,
      },
      accessToken
    );
  }

  async createClientToken(id: string) {
    let customerUrl = 'https://api-sandbox.dwolla.com/customers/' + id;
    let dwollaRequest = {
      action: "customer.fundingsources.create",
      links: {
        customer:{
          href: customerUrl
        }
      },
      _links: {
        customer: {
          href: customerUrl
        }
      }
    };
    return this.apiService.postV1(
      dwollaRequest,
      {
        m: 'Payment',
        a: 'CreateClientToken',
        id: id,
      },
    );
  }

  async removeFundingSource(id: string) {
    return this.apiService.postV1(
      undefined,
      {
        id: id,
        m:'Payment',
        a:'RemoveFundingSource',
      });
  }


  async addFundingSource(url: string, body: string, clientToken:any) {
    return await fetch(url, {
      method: 'POST',
      body: body,
      headers: {
        'Accept': 'application/vnd.dwolla.v1.hal+json',
        'Content-Type': 'application/vnd.dwolla.v1.hal+json',
        'Authorization': 'Bearer ' + clientToken['token']
      }
    });
  }

  async getFundingSourceMicroDepositStatus(fundingSourceId: string) {
    return this.apiService.getV1(this.controller, 'GetFundingSourceMicroDepositStatus', {id: fundingSourceId});
  }
}
