import { Component, Input, ViewChild } from '@angular/core';
import { AlertController, IonModal, ModalController } from '@ionic/angular';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { IKioskPastOrder } from 'src/app/core/models/kiosks/kiosk-past-order.model';
import { KioskPurchasesService } from 'src/app/core/services/api/modern/kiosk-purchases/kiosk-purchases.service';
import { KioskSessionsService } from 'src/app/core/services/api/modern/kiosk-sessions/kiosk-sessions.service';

@Component({
  selector: 'app-kiosk-past-order',
  templateUrl: './past-order-modal.component.html',
  styleUrls: ['./past-order-modal.component.scss'],
})
export class KioskPastOrderComponent {
  @ViewChild(IonModal) modal!: IonModal;
  @Input() pastOrders!: IKioskPastOrder[];
  @Input() kioskSessionID!: number;
  @Input() organizationID!: number;
  @Input() accessToken!: string;

  sendReceiptPhoneNumber: string = '';
  selectedOrder: IKioskPastOrder = {} as IKioskPastOrder;
  modalTitle: 'Past Orders' | 'Order Details' = 'Past Orders';

  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private kioskSessionsService: KioskSessionsService,
    private kioskPurchasesService: KioskPurchasesService
  ) {}

  close() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  back() {
    this.modalTitle = 'Past Orders';
  }

  orderDetail(order: IKioskPastOrder) {
    this.selectedOrder = order;
    this.modalTitle = 'Order Details';
  }

  async resendReceipt() {
    const alert = await this.alertController.create({
      header: 'Resend Text Receipt',
      inputs: [
        {
          placeholder: 'Phone Number',
          type: 'number',
          name: 'phoneNumber',
          value: this.selectedOrder.PhoneNumber,
        },
      ],
      message: 'If the original phone number was incorrect, change it below.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Submit',
          handler: async (alertData) => {
            if (!alertData.phoneNumber) {
              return;
            }

            if (!isValidPhoneNumber('+1 ' + alertData.phoneNumber)) {
              const alert = await this.alertController.create({
                header: 'Invalid Phone Number',
                message: 'Please enter a valid phone number.',
                buttons: ['OK'],
              });

              await alert.present();

              return;
            }

            const response = await this.kioskPurchasesService.sendReceipt(
              this.kioskSessionID.toString(),
              this.selectedOrder.PaymentIntentID!,
              alertData.phoneNumber
            );

            if (response.status >= 200 && response.status < 300) {
              const alert = await this.alertController.create({
                header: 'Resend Text Receipt',
                message: 'The receipt has been resent successfully.',
                buttons: ['OK'],
              });

              await alert.present();
            }
          },
        },
      ],
    });

    await alert.present();
  }

  async refundPurchase() {
    const foundOrder = this.pastOrders.find((o) => o === this.selectedOrder);
    let response = null;
    if (foundOrder) {
      foundOrder.Refunded = true;
    }

    if (this.selectedOrder.Type === 'Credit') {
      response = await this.kioskSessionsService.refund(
        this.selectedOrder.PaymentIntentID!.toString(),
        this.kioskSessionID.toString(),
        this.organizationID.toString()
      );
    } else {
      response = await this.kioskSessionsService.refundCash(
        this.selectedOrder.KioskOrderID!.toString(),
        this.kioskSessionID.toString(),
        this.organizationID.toString()
      );
    }

    if (response.status >= 200 && response.status < 300) {
      const alert = await this.alertController.create({
        header: 'Refund Complete',
        message: 'The refund has been completed successfully.',
        buttons: ['OK'],
      });

      await alert.present();
    }
  }
}
