<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="modalCtrl.dismiss()"> Cancel </ion-button>
    </ion-buttons>
    <ion-title>{{ mode }} {{ modalType | titlecase }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div id="form-container" *ngIf="modalType === 'contact'">
    <br />
    <ion-list lines="full">
      <ion-item>
        <ion-input labelPlacement="stacked" placeholder="" [(ngModel)]="contact.Title">
          <div slot="label">Nickname<ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-select label-placement="stacked" [(ngModel)]="contact.ContactType">
          <div slot="label">Type</div>
          <ion-select-option value="Email">Email</ion-select-option>
          <ion-select-option value="Phone">Phone</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-input
          labelPlacement="stacked"
          placeholder=""
          [(ngModel)]="contact.Value"
          *ngIf="contact.ContactType === 'Email'"
          (keyup.enter)="save()">
          <div slot="label">Email Address<ion-text color="danger">*</ion-text></div>
        </ion-input>
        <ion-input
          labelPlacement="stacked"
          placeholder=""
          [(ngModel)]="contact.Value"
          type="text"
          inputmode="tel"
          *ngIf="contact.ContactType === 'Phone'"
          (keyup.enter)="save()">
          <div slot="label">Phone Number<ion-text color="danger">*</ion-text></div>
        </ion-input>
      </ion-item>
      <br />
    </ion-list>
    <ion-button
      expand="block"
      class="ion-padding-horizontal"
      color="danger"
      (click)="deleteContact()"
      *ngIf="mode === 'Edit'"
      >Delete Contact</ion-button
    >
  </div>
  <div id="form-container" *ngIf="modalType === 'sport'">
    <br />
    <ion-list lines="full">
      <ion-item *ngIf="mode === 'Add'">
        <ion-select label-placement="stacked" [(ngModel)]="sport">
          <div slot="label">Sport</div>
          <ion-select-option [value]="sport" *ngFor="let sport of sportsList">{{ sport.Title }}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-input
          type="text"
          inputmode="tel"
          labelPlacement="stacked"
          placeholder=""
          inputMode="numeric"
          [(ngModel)]="sport.YearsOfExperience"
          (keyup.enter)="save()">
          <div slot="label">Years Of Experience (YoE)</div>
        </ion-input>
      </ion-item>
      <br />
    </ion-list>
    <ion-button
      expand="block"
      class="ion-padding-horizontal"
      color="danger"
      (click)="deleteSport()"
      *ngIf="mode === 'Edit'"
      >Delete Sport</ion-button
    >
  </div>

  <ion-button class="save-button ion-padding-horizontal" expand="block" (click)="save()">Save</ion-button>
</ion-content>
