import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import IContextOrganizationModel from 'src/app/core/models/context/context-organization.models';
import IContextModel from 'src/app/core/models/context/context.model';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  applicationType$!: BehaviorSubject<string>;
  organizationName$!: BehaviorSubject<string>;
  expanded: boolean = false;
  context!: IContextModel;
  showAccountPicker: boolean = true;
  organizations!: IContextOrganizationModel[];
  filteredOrganizations!: IContextOrganizationModel[];
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.context = await this.authService.getContext();
    this.organizations = (await this.authService.getContextOrganizations()).sort((a, b) =>
      a.OrganizationTitle!.localeCompare(b.OrganizationTitle!)
    );
    const organization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);

    this.applicationType$ = new BehaviorSubject<string>(this.context.ApplicationType!);
    let organizationName = organization ? organization.OrganizationTitle! : 'No Organization';
    this.organizationName$ = new BehaviorSubject<string>(organizationName);
  }

  async accountSelection(accountSelection: string) {
    this.applicationType$.next(accountSelection);

    this.filteredOrganizations =
      accountSelection === 'Staff'
        ? this.organizations.filter((org) => org.IsStaffOrganization)
        : this.organizations;

    if (accountSelection === 'Officials') {
      await this.completeContextChange(accountSelection);
      return;
    }

    this.showAccountPicker = false;
  }

  async setActiveOrganization(organization: IContextOrganizationModel) {
    await this.completeContextChange(this.applicationType$.getValue(), organization);
  }

  async completeContextChange(applicationType: string, organization?: IContextOrganizationModel) {
    this.context.ApplicationType = applicationType;
    this.authService.applicationType$.next(applicationType);
    if (organization) {
      this.context.OrganizationId = organization.ID;
      this.organizationName$.next(organization.OrganizationTitle!);
    }
    await this.authService.updateContext(this.context);
    this.router.navigate(['/']);
    this.toggleExpanded();
  }

  toggleExpanded() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      // Reset menu
      this.showAccountPicker = true;
      this.applicationType$.next(this.context.ApplicationType!);
    }
  }

  navigateTo(flag: string) {
    this.expanded = false;
    this.router.navigate([flag]);
  }

  logOut() {
    this.authService.deleteContext();
    this.router.navigate(['/login']);
  }
}
