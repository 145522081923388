import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AssignmentsService } from '../../../../core/services/api/legacy/assignments/assignments.service';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-accept-assignment',
  templateUrl: './accept-assignment.component.html',
  styleUrls: ['./accept-assignment.component.scss'],
})
export class AcceptAssignmentComponent implements OnInit {
  context!: ContextModel;
  @Input() reason: string;
  eventId: any;
  linkedId: any;
  contract: any;
  openAssign: any;
  title?: any;
  fee?: any;
  slotId?: any;
  accessToken: any;

  constructor(
    private authService: AuthService,
    public modalCtrl: ModalController,
    private assignmentsService: AssignmentsService
  ) {
    if (!this.eventId) {
      this.eventId = '';
    }
    if (!this.linkedId) {
      this.linkedId = false;
    }

    this.reason = '';
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  acceptAssignment() {
      this.assignmentsService.acceptAssignment(
        this.eventId,
        this.linkedId,
        this.openAssign,
        this.context.AccessToken as string,
      );
    this.modalCtrl.dismiss(this.eventId);
  }
}
