<ion-tabs>
  <ion-tab-bar slot="home">
    <ion-tab-button tab="home">
      <div class="icon-container">
        <ion-img src="../../../../assets/images/appIcon/appicon.png" class="home-icon"></ion-img>
        <ion-text>Home</ion-text>
      </div>
    </ion-tab-button>
    <ion-tab-button tab="calendar">
      <div class="icon-container">
        <fa-icon icon="calendar-days" size="2x" class="icon"></fa-icon>
        Calendar
      </div>
    </ion-tab-button>
    <ion-tab-button tab="games">
      <div class="icon-container">
        <fa-icon icon="person-running" size="2x"></fa-icon>
        Games
      </div>
    </ion-tab-button>
    <ion-tab-button tab="blocks">
      <div class="icon-container">
        <fa-icon icon="ban" size="2x" class="icon"></fa-icon>
        Blocks
      </div>
    </ion-tab-button>
    <ion-tab-button id="popover" selected="false">
      <div class="icon-container" id="popover-button">
        <fa-icon icon="bars" size="2x" class="icon"></fa-icon>
        More
      </div>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>


<ion-popover trigger="popover-button" [dismissOnSelect]="false">
  <ng-template>
    <ion-content>
      <ion-list>
        <ion-item [button]="true" [detail]="false" (click)="payRouting()">Pay</ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>
