<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Search Payments</ion-title>
    <ion-textarea style="width: 25%;" slot="end"></ion-textarea>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding-top">
  <form [formGroup]="formGroup">
    <ion-text class="ion-padding-start text-start top-line"> Payor</ion-text>
    <ion-item class="show-inputs background-transparent p3-padding">
      <ion-input formControlName="payor"></ion-input>
    </ion-item>
    <ion-text class="ion-padding-start text-start top-line text-top-margin"> Amount</ion-text>
    <ion-item class="show-inputs background-transparent p3-padding">
      <ion-text style="width: 10%">$</ion-text><ion-input style="width: 40%" formControlName="lowerAmount" type="number"></ion-input><ion-text style="width: 10%"> to $</ion-text><ion-input style="margin-left: 5px; width: 40%" formControlName="upperAmount" type="number"></ion-input>
    </ion-item>
    <ion-text class="ion-padding-start text-start top-line text-top-margin"> Date Range</ion-text>
    <ion-item>
      <ion-select  name="date" id="state" class="select-state p3-padding" formControlName="dateRange">
        <ion-select-option value="30" selected="selected">Last 30 Days
        </ion-select-option>
        <ion-select-option value="90">Last 90 Days
        </ion-select-option>
        <ion-select-option value="180" >Last 180 Days
        </ion-select-option>
        <ion-select-option value="Custom">Custom Range
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-grid *ngIf="this.formGroup.get('dateRange')?.value === 'Custom'" style="margin-bottom: 30dvh">
      <ion-row class="ion-padding-right ion-padding-top"> <ion-text style="margin-left: 20px"> From  </ion-text>  </ion-row>
      <ion-row class="ion-padding"><ion-datetime id="datetime1" presentation="date" formControlName="customEarlierDate"></ion-datetime></ion-row>
      <ion-row class="ion-padding-right"> <ion-text style="margin-left: 20px">To</ion-text></ion-row>
      <ion-row class="ion-padding"><ion-datetime id="datetime2" presentation="date" formControlName="customLaterDate"></ion-datetime></ion-row>
    </ion-grid>
  </form>
  <div style="width: 100%;
    position: fixed;
    bottom: 20%;
    display: block;
    background:var(--background);padding-bottom: 5%;">
    <ion-button
      class="terms-button ion-padding"
      type="submit"
      appPreventMultiClickEvents
      (preventMultiClicks)="search()"
      expand="block"
      [disabled]="!formGroup.valid">
      <ion-text> Search </ion-text>
    </ion-button>
  </div>
</ion-content>
