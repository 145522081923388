import {Component, Input, OnInit} from '@angular/core';
import { ModalController } from '@ionic/angular';
import {ApiService} from "../../../../core/services/api/api.service";
import {EventService} from "../../../../core/services/api/legacy/event/event.service";
import IContextModel from "../../../../core/models/context/context.model";
import {AuthService} from "../../../../core/services/auth/auth.service";
import IContextOrganizationModel from "../../../../core/models/context/context-organization.models";

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit{
  @Input() event: any;
  context!: IContextModel;
  organization?: IContextOrganizationModel;
  foundEvent: any;

  constructor(public modalCtrl: ModalController, private apiService: ApiService, private eventService: EventService, private authService: AuthService) {
  }

  async ngOnInit() {
    this.context = await this.authService.getContext();
    this.organization = await this.authService.getContextOrganizationById(this.context.OrganizationId!);

    const { data } = await this.eventService.findEvent(this.event.ID);
    this.foundEvent = data['Data'];
    if(this.foundEvent.StartDateTime){
    let dateTime:string = this.foundEvent.StartDateTime.toString();

    let dateSplit = dateTime.split('T');

    let date = dateSplit[0];
    let components = date.split('-');

    if(Number(components[1]) < 10){
      components[1] = components[1].replace('0', '');
    }
    if(Number(components[2]) < 10){
      components[2] = components[2].replace('0', '');
    }
    let newDate = "";
    newDate = newDate.concat(components[1]);
    newDate = newDate.concat('/' + components[2]);
    newDate = newDate.concat('/' + components[0]);
    if(dateSplit.length > 1){
      let newTime = this.splitTime(dateSplit);
      newDate = newDate.concat(', ' + newTime)

        if(this.foundEvent.EndDateTime && this.foundEvent.EndDateTime.split('T').length > 1){
          let endTime = this.splitTime(this.foundEvent.EndDateTime.split('T'));
          newDate = newDate.concat(' - ' + endTime);
        }
      }
    this.foundEvent.StartDateTime =  newDate;
  } else {
      this.foundEvent.StartDateTime = "TBD";
}
  }


  private splitTime(dateSplit: string[]) {
    let newTime = "";
    let components = dateSplit[1].split(':');
    let m = '';
    let numberValue: number = +components[0];
    if (numberValue <= 12) {
      m = 'AM';
    } else {
      numberValue = numberValue - 12;
      components[0] = numberValue.toString();
      m = 'PM';
    }
    newTime = newTime.concat(components[0]);
    newTime = newTime.concat(':' + components[1]);
    return newTime.concat(' ' + m);
  }
}
