import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class AssignmentsService {
  constructor(private apiService: ApiService) {}

  async getAssignments(accessToken: string) {
    return await this.apiService.getV1(
      'EventAthletics',
      'GetAllWithSelectiveAssignAvailableByCurrentOfficial',
      {
        start: moment().format('YYYY-MM-DD'),
        end: moment().add(5, 'years').startOf('day').format('YYYY-MM-DD'),
        tz: moment.tz.guess(),
      },
      accessToken
    );
  }

  async getAssignmentsWithTime(start:any, end:any) {
    return await this.apiService.getV1(
      'EventAthletics',
      'GetAllWithSelectiveAssignAvailableByCurrentOfficial',
      {
        start: start,
        end: end,
        tz: moment.tz.guess(),
      },
    );
  }

  async getAllDeclinedByCurrentOfficial(accessToken: string){
    return await this.apiService.getV1(
      'EventAthletics',
      'GetAllDeclinedByCurrentOfficial',
      {
        start: moment().format('YYYY-MM-DD'),
        end: moment().add(5, 'years').startOf('day').format('YYYY-MM-DD'),
        tz: moment.tz.guess(),
      },
      accessToken
    );
  }

  async getAllDeclinedByCurrentOfficialWithTime(accessToken: string, start:any, end:any){
    return await this.apiService.getV1(
      'EventAthletics',
      'GetAllDeclinedByCurrentOfficial',
      {
        start:start,
        end:end,
        tz: moment.tz.guess(),
      },
      accessToken
    );
  }

  async getOpenAssignments(accessToken: string) {
    return await this.apiService.getV1(
      'EventAthletics',
      'GetAllWithOpenAssignAvailable',
      {
        start: moment().format('YYYY-MM-DD'),
        end: moment().add(5, 'years').startOf('day').format('YYYY-MM-DD'),
        tz: moment.tz.guess(),
      },
      accessToken
    );
  }

  async getOpenAssignmentsWithTime(accessToken: string, start:any, end:any) {
    return await this.apiService.getV1(
      'EventAthletics',
      'GetAllWithOpenAssignAvailable',
      {
        start: start,
        end:end,
        tz: moment.tz.guess(),
      },
      accessToken
    );
  }

  async getAcceptedAssignments(accessToken: string) {
    return await this.apiService.getV1(
      'EventAthletics',
      'GetAllWithAcceptedSlotsByCurrentOfficial',
      {
        start: moment().format('YYYY-MM-DD'),
        end: moment().add(5, 'years').startOf('day').format('YYYY-MM-DD'),
        tz: moment.tz.guess(),
      },
      accessToken
    );
  }

  async getAcceptedAssignmentsWithTime(accessToken: string, start:any, end:any) {
    return await this.apiService.getV1(
      'EventAthletics',
      'GetAllWithAcceptedSlotsByCurrentOfficial',
      {
        start: start,
        end: end,
        tz: moment.tz.guess(),
      },
      accessToken
    );
  }

  async acceptAssignment(eventId: any, linkedId: any, isOpenAssign: boolean, accessToken: string) {
    let eventIdArray = [];

    if (linkedId) {
      const { data } = await this.getLinked(eventId, accessToken);
      eventIdArray = data.Data.map((event: any) => event.ID);
    }

    eventIdArray.push(eventId);

    let linkedIdString = linkedId ? 'true' : 'false';

    let body = {
      Linked: linkedIdString,
      EventIDs: eventIdArray,
      IsOpenAssign: isOpenAssign,
    };

    await this.apiService.postV1(
      body,
      {
        m:'EventOfficialSlot',
        a:'Accept',
      },
      undefined,
      accessToken
    );
  }

  async declineAssignment(
    eventId: string,
    linkedId: boolean,
    declineReason: string,
    openAssign: boolean,
    accessToken: string
  ) {
    let eventIdArray = [];
    eventIdArray.push(eventId);

    if (linkedId) {
      const { data } = await this.getLinked(eventId, accessToken);
      eventIdArray = data.Data.map((event: any) => event.ID);
    }

    let linkedIdString = linkedId ? 'true' : 'false';
    await this.apiService.postV1(
      {
      },
      {
        m:  'EventOfficialSlot',
        a: 'Decline',
        linked: linkedIdString,
        eventIDs: eventIdArray,
      },
      undefined,
      accessToken
    );
  }

  async declineOpenAssignment(title: string, fee: string, slotId: string, accessToken: string) {
    await this.apiService.postV1(
      { EventOfficialSlotID: slotId, Type: 'OpenAssign', Title: title, Fee: fee },
      {   m:   'EventOfficialSlot',
        a: 'Decline'},
      undefined,
      accessToken
    );
  }
  async getLinked(eventId: string, accessToken: string) {
    return await this.apiService.getV1(
      'EventAthletics',
      'GetLinkedByEventID',
      {
        id: eventId,
      },
      accessToken
    );
  }

  async acceptOpenAssign(title: string, fee: string, slotId: string, accessToken: string) {
    await this.apiService.postV1(
      { EventOfficialSlotID: slotId, IsOpenAssign: true, Type: 'OpenAssign', Title: title, Fee: fee },
      {m: 'EventOfficialSlot',
        a: 'Accept'},
      undefined,
      accessToken
    );
  }
}
