import { Component, Input, ViewChild } from '@angular/core';
import { AlertController, IonModal, ModalController } from '@ionic/angular';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { StripeTerminal } from '@capacitor-community/stripe-terminal';
import { IKioskOrderRequest } from 'src/app/core/models/kiosks/kiosk-order-request.model';
import { KioskPurchasesService } from 'src/app/core/services/api/modern/kiosk-purchases/kiosk-purchases.service';
import { IKioskOrderResponse } from 'src/app/core/models/kiosks/kiosk.order.response.model';

@Component({
  selector: 'app-kiosk-card-checkout',
  templateUrl: './kiosk-card-modal.component.html',
  styleUrls: ['./kiosk-card-modal.component.scss'],
})
export class KioskCheckoutComponent {
  @ViewChild(IonModal) modal!: IonModal;
  @Input() kioskCheckoutRequest!: IKioskOrderRequest;
  @Input() accessToken!: string;

  sendReceiptPhoneNumber: string = '';
  kioskCheckoutResponse!: IKioskOrderResponse;
  modalTitle: string = 'Send Receipt';

  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private kioskPurchaseService: KioskPurchasesService
  ) {}

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async skipPhoneNumber() {
    await this.kioskCardCheckout();
  }

  async applyPhoneNumber() {
    if (!isValidPhoneNumber('+1 ' + this.sendReceiptPhoneNumber)) {
      const alert = await this.alertController.create({
        header: 'Invalid Phone Number',
        message: 'Please enter a valid phone number.',
        buttons: ['OK'],
      });

      await alert.present();

      return;
    }

    this.kioskCheckoutRequest.ReceiptPhoneNumber = this.sendReceiptPhoneNumber;

    await this.kioskCardCheckout();
  }

  async kioskCardCheckout() {
    this.modalTitle = 'Checkout';

    const data = await this.kioskPurchaseService.startCheckout(this.kioskCheckoutRequest);

    if (data.status != 200) {
      await this.modalCtrl.dismiss({ success: false, phoneNumber: '', paymentIntentID: '' }, 'error');
      return;
    }

    this.kioskCheckoutResponse = data.data;
  }

  async swipeCreditCard() {
    const alert = await this.alertController.create({
      header: 'Transaction in Progress',
      message: 'Please swipe, tap, or insert your card to complete the transaction.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          handler: async () => {
            await StripeTerminal.cancelCollectPaymentMethod();
            return this.modalCtrl.dismiss(
              {
                success: false,
                phoneNumber: '',
                paymentIntentID: '',
              },
              'cancel'
            );
          },
        },
      ],
    });

    await alert.present();

    await StripeTerminal.collectPaymentMethod({
      paymentIntent: this.kioskCheckoutResponse.PaymentIntentClientSecret,
    });

    try {
      await StripeTerminal.confirmPaymentIntent();
    } catch (error: any) {
      await alert.dismiss();

      const declineAlert = await this.alertController.create({
        header: 'Transaction Declined',
        message: error.message,
        backdropDismiss: false,
        buttons: [
          {
            text: 'OK',
          },
        ],
      });

      await declineAlert.present();

      await StripeTerminal.cancelCollectPaymentMethod();
      return this.modalCtrl.dismiss(
        {
          success: false,
          phoneNumber: '',
          paymentIntentID: '',
        },
        'error'
      );
    }

    await alert.dismiss();

    return this.modalCtrl.dismiss(
      {
        success: true,
        phoneNumber: this.sendReceiptPhoneNumber,
        paymentIntentID: this.kioskCheckoutResponse.StripePaymentIntentID,
      },
      'confirm'
    );
  }
}
