import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import {OfficialService} from "../../../../core/services/api/legacy/official/official.service";

@Component({
  selector: 'app-official-information',
  templateUrl: './official-information.component.html',
  styleUrls: ['./official-information.component.scss'],
})
export class OfficialInformationComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  eventId: any;
  linkedId: any;
  official: any;
  officialContactPoints?: any = new Array<any>();

  constructor(
    private authService: AuthService,
    public modalCtrl: ModalController,
    private officialServices: OfficialService,
  ) {
    if (!this.eventId) {
      this.eventId = '';
    }

    if (!this.linkedId) {
      this.linkedId = false;
    }
  }
  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
    let {data: officialData} = await this.officialServices.find(this.official.ID)

    if (this.official == null || !officialData) return;
    this.officialContactPoints = officialData.Data['ContactPoints'].map((contactPoint: any) => {
      return { nickname: contactPoint['Title'], value: contactPoint['Value'] };
    });
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  copyText(value:string){
    navigator.clipboard.writeText(value);
  }
}

