import { Component, Input, ViewChild } from '@angular/core';
import { IonModal, ModalController} from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import {MessageService} from "../../../../core/services/api/legacy/message/message.service";
import {SuccessService} from "../../../../core/services/success/success.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  @Input() username: string;

  constructor(
    private successService: SuccessService,
    private messageService : MessageService,
    public modalCtrl: ModalController,
  ) {

    this.username = '';
  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  async submit() {
    let promise = await this.messageService.sendPasswordResetEmail(this.username);

    if(promise.status >= 200 && promise.status < 300){
      this.successService.showSuccess('An email has been sent to reset your password.', 5000, 'Email Sent');
    }

    this.modalCtrl.dismiss();
  }
}
