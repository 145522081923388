import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root',
})
export class AboundService {
  private readonly controller = 'Abound';

  constructor(private apiService: ApiService) {}

  getForUser(userToken: string) {
    let params = { userToken };

    return this.apiService.get(this.controller, 'GetForUser', params);
  }

  getForCurrentUser() {
    return this.apiService.get(this.controller, 'GetForCurrentUser', undefined);
  }

  getForExchangedToken(exchangeableToken: string) {
    let params = { exchangeableToken };

    return this.apiService.get(this.controller, 'GetForExchangedToken', params);
  }

  createTokenForUser(userToken: string, aboundUserID: string) {
    let params = { userToken, aboundUserID };

    return this.apiService.post(this.controller, 'CreateTokenForUser', {}, params);
  }

  createTokenForCurrentUser() {
    return this.apiService.post(this.controller, 'CreateTokenForCurrentUser', undefined, undefined);
  }

  createTokenForExchangedUser(exchangeableToken: string, aboundUserID: string) {
    let params = { exchangeableToken, aboundUserID };

    return this.apiService.post(this.controller, 'CreateTokenForExchangedUser', {}, params);
  }

  createDocumentsTokenForCurrentUser(password: string) {
    return this.apiService.post(
      this.controller,
      'CreateDocumentsTokenForCurrentUser',
      { Password: password },
      undefined
    );
  }

  setElectronicConsentForUser(userToken: string) {
    let params = { userToken };

    return this.apiService.post(this.controller, 'SetElectronicConsentForUser', {}, params);
  }

  setElectronicConsentForCurrentUser() {
    return this.apiService.post(this.controller, 'SetElectronicConsentForCurrentUser');
  }

  setElectronicConsentForExchangedUser(exchangeableToken: string) {
    let params = { exchangeableToken };

    return this.apiService.post(this.controller, 'SetElectronicConsentForExchangedUser', {}, params);
  }
}
