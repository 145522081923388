import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, PopoverController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import IContextModel from 'src/app/core/models/context/context.model';
import { IKioskAccess } from 'src/app/core/models/kiosks/kiosk-access.model';
import { KioskAccessService } from 'src/app/core/services/api/modern/kiosk-access/kiosk-access.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { UploadLogsService } from 'src/app/core/services/upload-logs/upload-logs.service';

@Component({
  selector: 'app-community-footer',
  templateUrl: './community-footer.component.html',
  styleUrls: ['./community-footer.component.scss'],
})
export class CommunityFooterComponent implements OnInit {
  userHasKioskAccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  context!: IContextModel;
  showRefresh: boolean = true;

  constructor(
    private kioskAccessService: KioskAccessService,
    private authService: AuthService,
    private popoverController: PopoverController,
    private router: Router,
    private uploadLogsService: UploadLogsService,
    private alertController: AlertController
  ) {}

  async ngOnInit() {
    this.context = await this.authService.getContext();

    await this.refresh();
  }

  async dismissPopover() {
    await this.popoverController.dismiss();
  }

  async refresh() {
    if (this.userHasKioskAccess.getValue()) {
      await this.popoverController.dismiss();
      this.showRefresh = true;

      return;
    }

    const { data }: { data: IKioskAccess[] } = await this.kioskAccessService.getForUser(this.context.AccessToken!);

    if (data.length < 1) return;
    this.showRefresh = false;
    this.userHasKioskAccess.next(true);
  }

  async uploadLogs() {
    const alert = await this.alertController.create({
      header: 'Upload Logs',
      message: 'Did Eventlink support ask you to upload these logs?',
      buttons: [
        {
          text: 'Yes',
          handler: async () => {
            await this.uploadLogsService.uploadLogs();
            await this.popoverController.dismiss();
          },
        },
        {
          text: 'No',
        },
      ],
      backdropDismiss: false,
    });

    await alert.present();
  }

  async kiosk() {
    await this.router.navigate(['/kiosk']);
    this.authService.isAuthenticated$.next(false);
  }

  async pay() {
    await this.router.navigate(['/pay']);
    await this.popoverController.dismiss();
    this.authService.isAuthenticated$.next(false);
  }

}
