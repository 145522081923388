import * as JSZip from 'jszip';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AuthService } from '../auth/auth.service';
import { MobileLogsSubmissionsApiService } from '../api/v2/MobileLogsSubmissionsApi.service';

@Injectable({
  providedIn: 'root',
})
export class UploadLogsService {
  constructor(
    private mobileLogsSubmissionsApi: MobileLogsSubmissionsApiService,
    private alertController: AlertController,
    private authService: AuthService
  ) {}

  async uploadLogs() {
    const logs = await this.authService.getLogEntries();

    if (logs.length == 0) return;

    let zip = new JSZip();

    const csvFile = logs
      .map((e) => {
        return `${e.DateTime},${e.UserID},${e.Message?.replace(',', '')},${e.File},${e.Method}`;
      })
      .join('\n');

    zip.file('eventlink/log.csv', csvFile);

    const response = await this.mobileLogsSubmissionsApi.submit(await zip.generateAsync({ type: 'blob' }));

    if (response.isSuccess) {
      const referenceNumberString = response.data?.ID
        ? `Your upload reference number is ${response.data.ID}.`
        : '';

      const alert = await this.alertController.create({
        header: 'Log Submission Uploaded',
        message: `
        Your logs have been successfully uploaded. Thank you!
        
        ${referenceNumberString}
        `,
        buttons: ['OK'],
      });

      await alert.present();
      await this.authService.deleteLogs();
    }
  }
}
