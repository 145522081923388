import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import {PaymentService} from "../../../../core/services/api/modern/payment/payment.service";
import {UserLegacyService} from "../../../../core/services/api/legacy/user/user.legacy.service";

@Component({
  selector: 'app-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.scss'],
})
export class AcceptTermsComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;

  constructor(
    private authService: AuthService,
    public modalCtrl: ModalController,
    private paymentService: PaymentService,
    private userLegacyService : UserLegacyService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  acceptTerms() {
    this.userLegacyService.acceptTermsOfService(this.context.AccessToken as string);
    this.modalCtrl.dismiss(true);
  }
}
