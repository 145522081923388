<ion-app *ngIf="isAuthenticated$ | async; let isAuthenticated; else: notAuthorized">
  <app-header></app-header>
  <ion-router-outlet></ion-router-outlet>

  <app-loading-indicator />

  <app-community-footer *ngIf="(applicationType$ | async) === 'Community'"></app-community-footer>

  <app-officials-footer *ngIf="(applicationType$ | async) === 'Officials'"></app-officials-footer>

  <app-staff-footer *ngIf="(applicationType$ | async) === 'Staff'"></app-staff-footer>
</ion-app>

<ng-template #notAuthorized>
  <ion-app>
    <ion-router-outlet></ion-router-outlet>
  </ion-app>
</ng-template>
