import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import ContextModel from '../../../../core/models/context/context.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import {PaymentService} from "../../../../core/services/api/modern/payment/payment.service";

@Component({
  selector: 'app-update-information',
  templateUrl: './update-information.component.html',
  styleUrls: ['./update-information.component.scss'],
})

export class UpdateInformationComponent implements OnInit {
  @ViewChild(IonModal) modal!: IonModal;
  context!: ContextModel;
  customer: any;
  customerId: any;
  @Input() FirstName! : string;
  @Input() LastName! : string;
  @Input() Email! : string;

  constructor(
    private authService: AuthService,
    public modalCtrl: ModalController,
    private paymentService: PaymentService,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.context = await this.authService.getContext();
  }

  cancel() {
    return this.modalCtrl.dismiss(false);
  }

  async save() {
    let customerData = {
      FirstName: this.FirstName,
      LastName: this.LastName,
      Email: this.Email,
    };
    await this.paymentService.updateDwollaCustomer(customerData, this.customerId, this.context.AccessToken!)
    this.modalCtrl.dismiss(true);
  }
}
