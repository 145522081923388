<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button type="submit" (click)="cancel()" expand="block">
        <ion-text> Cancel </ion-text>
      </ion-button>
    </ion-buttons>
    <ion-title>Decline Assignment</ion-title>
    <ion-buttons slot="end">
      <ion-button
        type="submit"
        appPreventMultiClickEvents
        (preventMultiClicks)="declineAssignment()"
        expand="block">
        <ion-text> Decline </ion-text>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  Are you sure you want to decline this assignment?
</ion-content>
